<template>
  <div class="product">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge1+')'}" id="product1">
          <div class="product1left" :style="{backgroundImage:'url('+homeItem1.typePic+')'}"  :class="activeIndex==0?'animate__animated animate__bounceInLeft':''"></div>
          <div class="product1right" :class="activeIndex==0?'animate__animated animate__bounceInRight':''">
            <div class="product1right1">
              产品与解决方案
            </div>
            <div class="product1right2">
              {{homeItem1.remark}}
            </div>
            <div class="product1right2item">
              <div id="advert">
                <div class="bg1">
                  <div>
                    <img src="@/static/icon/icon-01.png" alt="" class="icon01">
                    <!--                    <img src="@/static/icon/icon-02.png" alt="" class="icon02">-->
                    <img src="@/static/icon/icon-03.png" alt="" class="icon03">
                    <img src="@/static/icon/icon-04.png" alt="" class="icon04">
                    <!--                    <img src="@/static/icon/icon-05.png" alt="" class="icon05">-->
                    <img src="@/static/icon/icon-06.png" alt="" class="icon06">
                    <!--                    <img src="@/static/icon/icon-07.png" alt="" class="icon07">-->
                  </div>
                </div>
                <div class="bg2">
                  <div>
                      <span class="icon08">
                        <img src="@/static/icon/icon-08.png" alt="" style="width: 1.5vw">
                      </span>
                    <!--                                    <span class="icon09">-->
                    <!--                        <img src="@/static/icon/icon-09.png" alt="" width="30px">-->
                    <!--                      </span>-->
                    <span class="icon10">
                        <img src="@/static/icon/icon-10.png" alt="" style="width: 2vw;height: 1.71vw">
                      </span>
                  </div>
                </div>
                <div class="bg3">
                  <div>
                    <span class="icon11">
                      <p class="icon11boder"></p>
<!--                      <img src="@/static/icon/icon-11.png" alt="" width="26px">-->
                    </span>
<!--                    <span class="icon12">-->
<!--                      <img src="@/static/icon/icon-12.png" alt="" width="18px">-->
<!--                    </span>-->
                  </div>
                </div>
                <div class="content">
                  <span>立即下载</span>
                  <img src="@/static/icon/finger.png" alt=""
                       style="height: 1.145vw;vertical-align: middle;">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge2+')'}" id="product2">
          <div class="product2_1"></div>
          <div class="product2_2"></div>
          <div class="studyAdhd3Title">
            <div class="allneibox" :class="activeIndex==1?'animate__animated animate__fadeInDownBig':''">
              <div>一键式海筛工具</div>
              <div class="studyAdhdborder"></div>
            </div>
            <div class="product2_3 allneibox" :class="activeIndex==1?'animate__animated animate__bounceInRight':''" v-if="homeItem2.remark">
              <div class="product2box">
              </div>
              <div class="product2box">
                <div class="product2box1" :style="{backgroundImage:'url('+homeItem2.typePic[0]+')'}" >
                </div>
                <div class="product2boxtext">
                  {{ homeItem2.remark[0].name }}
                </div>
              </div>
              <div class="product2box">
                <div class="product2box2"  :style="{backgroundImage:'url('+homeItem2.typePic[1]+')'}">
                </div>
                <div class="product2boxtext">
                  {{ homeItem2.remark[1].name }}
                </div>
              </div>
              <div class="product2box">
                <div class="product2box3" :style="{backgroundImage:'url('+homeItem2.typePic[2]+')'}">
                </div>
                <div class="product2boxtext">
                  {{ homeItem2.remark[2].name }}
                </div>
              </div>
              <div class="product2box">
                <div class="product2box4" :style="{backgroundImage:'url('+homeItem2.typePic[3]+')'}">
                </div>
                <div class="product2boxtext">
                  {{ homeItem2.remark[3].name }}
                </div>
              </div>
              <div class="product2box">
                <div class="product2box5" :style="{backgroundImage:'url('+homeItem2.typePic[4]+')'}">
                </div>
                <div class="product2boxtext">
                  {{ homeItem2.remark[4].name }}
                </div>
              </div>
              <div class="product2box">
                <div class="product2box6" :style="{backgroundImage:'url('+homeItem2.typePic[5]+')'}">
                </div>
                <div class="product2boxtext">
                  {{ homeItem2.remark[5].name }}
                </div>
              </div>
              <div class="product2box">
                <div class="product2box7" :style="{backgroundImage:'url('+homeItem2.typePic[6]+')'}">
                </div>
                <div class="product2boxtext">
                  {{ homeItem2.remark[6].name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge3+')'}" id="product3">
          <div class="allneibox" style="height: 100%" v-if="homeItem3.typePic">
            <div class="product3left1" :style="{backgroundImage:'url('+homeItem3.typePic[1]+')'}" :class="activeIndex==2?'animate__animated animate__fadeInDown':''" ></div>
            <div class="product3left2" :style="{backgroundImage:'url('+homeItem3.typePic[0]+')'}" :class="activeIndex==2?'animate__animated animate__fadeInUp':''"></div>
            <div :class="activeIndex==2?'animate__animated animate__fadeInRightBig':''" class="product3right">
              <div class="studyAdhd3Title" style="margin-top: 0">
                <div>注意力训练</div>
                <div class="studyAdhdborder"></div>
              </div>
              <div class="product3rightText">
              {{homeItem3.remark}}
              </div>
              <div class="product3rightfotther"></div>
            </div>
          </div>
        </div>
        <div class="swiper-slide"  id="product4">
          <div class="product4Back" :style="{backgroundImage:'url('+homeImge4+')'}"></div>
          <div class="product4_1"></div>
          <div class="product4_2"></div>
          <div class="product4_3"></div>
          <div class="product4_4"></div>
          <div class="product4_5"></div>
          <div class="product4_6"></div>
          <div class="product4box" :class="activeIndex==3?'animate__animated animate__flipInX':''">
            <div class="product4box1">
              真实世界研究平台
            </div>
            <div class="product4box2">
              数据云架构，连接医院、学校、居家多场景；<br/>
              全面高效的实时数据系统；<br/>
              医师、家长、患儿、教师多角色可随时沟通及调整；<br/>
              全面数据整理，支持长期随访；<br/>
              实现需千万级构建的真实世界研究平台；<br/>
              可展开单项或多模态的真实研究；<br/>
              数据有迹可循，真实可信，研究可靠。
            </div>
          </div>

        </div>
        <div class="swiper-slide" id="product5">
          <div class="product5back"></div>
          <div class="studyAdhd3Title">
            <div class="allneibox">
              <div :class="activeIndex==4?'animate__animated animate__bounceInDown':''">数字化ADHD诊疗平台</div>
              <div class="studyAdhdborder" :class="activeIndex==4?'animate__animated animate__bounceInDown':''"></div>
              <div class="product5Text" :class="activeIndex==4?'animate__animated animate__bounceInDown':''">
                {{homeItem5.remark}}
              </div>
              <div class="product5fother" :class="activeIndex==4?'animate__animated animate__bounceInUp':''" :style="{backgroundImage:'url('+homeItem5.typePic+')'}">
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge6+')'}" id="product6">
          <div class="studyAdhd3Title" style="margin-top: 10vh" v-if="homeItem6.remark">
            <div class="allneibox">
              <div>ADHD二十年，looking back</div>
            </div>
            <div class="product6Back"></div>
            <div class="product6BT1 product6BackTitle"
                 :class="activeIndex==5?'animate__animated animate__bounceInDown':''">
              <div class="product6BackTitle1">{{ homeItem6.remark[0].nameData[0] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[0].nameData[1] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[0].nameData[2] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[0].nameData[3] }}</div>
            </div>
            <div class="product6BT2 product6BackTitle"
                 :class="activeIndex==5?'animate__animated animate__bounceInDown':''">
              <div class="product6BackTitle1">{{ homeItem6.remark[1].nameData[0] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[1].nameData[1] }}</div>
            </div>
            <div class="product6BT3 product6BackTitle"
                 :class="activeIndex==5?'animate__animated animate__bounceInDown':''">
              <div class="product6BackTitle1">{{ homeItem6.remark[2].nameData[0] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[2].nameData[1] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[2].nameData[3] }}</div>
            </div>
            <div class="product6BT4 product6BackTitle"
                 :class="activeIndex==5?'animate__animated animate__bounceInDown':''">
              <div class="product6BackTitle1">{{ homeItem6.remark[3].nameData[0] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[3].nameData[1] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[3].nameData[2] }}</div>
            </div>
            <div class="product6BT5 product6BackTitle"
                 :class="activeIndex==5?'animate__animated animate__bounceInDown':''">
              <div class="product6BackTitle1">{{ homeItem6.remark[4].nameData[0] }}</div>
              <div class="product6BackTitle2">{{ homeItem6.remark[4].nameData[1] }}</div>
            </div>


            <div :class="activeIndex==5?'animate__animated animate__bounceInUp':''"
                 class="product6Back1 product6Backtext">
              {{ homeItem6.remark[0].content }}
            </div>
            <div :class="activeIndex==5?'animate__animated animate__bounceInUp':''"
                 class="product6Back2 product6Backtext">
              {{ homeItem6.remark[1].content }}
            </div>
            <div :class="activeIndex==5?'animate__animated animate__bounceInUp':''"
                 class="product6Back4 product6Backtext">
              {{ homeItem6.remark[2].content }}
            </div>
            <div :class="activeIndex==5?'animate__animated animate__bounceInUp':''"
                 class="product6Back3 product6Backtext">
              {{ homeItem6.remark[3].content }}
            </div>
            <div :class="activeIndex==5?'animate__animated animate__bounceInUp':''"
                 class="product6Back5 product6Backtext">
              {{ homeItem6.remark[4].content }}
            </div>
          </div>
        </div>
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge7+')'}" id="product7">
          <div class="product7Back1"></div>
          <div class="product7Back2"></div>
          <div class="product7diqiu" :class="activeIndex==6?'animate__animated animate__fadeInLeftBig':''">
            <div class="product7diqiu1"></div>
            <div class="product7diqiu2"></div>
          </div>
          <div class="product7right" :class="activeIndex==6?'animate__animated animate__fadeInRightBig':''">
            <div class="product7rightTitle">
              多场景、全角色测评
            </div>
            <div class="product7rightcenter" v-if="homeItem7.remark">
              {{homeItem7.remark}}
            </div>
          </div>
        </div>
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge8+')'}" id="product8">
          <div class="product8Back" :class="activeIndex==7?'animate__animated animate__bounceInUp':''"></div>
          <div class="allneibox" style="height: 100%;color: white;text-align: center;margin-top: 10vh"
               :class="activeIndex==7?'animate__animated animate__bounceInDown':''">
            <div style="font-size: 1.8vw;margin-top: 4vh;display: inline-block;border-bottom: 2px solid;height: 6vh;">
              医教家全面测评
            </div>
            <div></div>
            <div style="width: 80%;margin: auto;margin-top: 4vh;font-size: 0.8vw">
              评估ADHD儿童有用的量表可以帮助临床医师了解儿童的症状、社会功能、共病、家庭环境等情况，用于辅助诊断。儿童行为评定量表多为他评量表，主要有父母用、教师用、专业人员用量表，年长儿则常用自评量表。国内外指南均推荐需要针对儿童所生活、学习、工作的所有场合、所有接触者均应该采集信息，辅助临床医师完整决策。
            </div>
            <div style="width: 80%;margin: auto;margin-top: 2vh;font-size: 0.8vw">
              父母评定量表：父母是与儿童接触最密切的人，对自己的孩子观察细微，是评价儿童行为的重要来源。尤其是儿童的情绪问题、躯体化问题要优于教师角色评价。教师评定量表：学校是学龄期儿童活动的重要场合，教师面向一个群体，能够对不同的儿童进行比较，因此评价儿童行为较为客观，尤其是多动、攻击等外化行性问题。
            </div>
            <div style="font-size: 1vw;margin-top: 10vh;font-size: 1.2vw;font-weight: bold">产品价值</div>
            <div style="width: 30%;margin: auto;margin-top: 2vh;font-size: 0.8vw">
              实现多角色、多场景全面评估，诊断更全面、更客观；多空间数字化互动测评，节省人力、空间，提升评测效能；严谨算法汇总分项因子&综合评分，提升报告解读质量；
            </div>
          </div>
        </div>
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge9+')'}" id="product9">
          <div class="product9Back1"></div>
          <div class="product9Back2"></div>
          <div class="studyAdhd3Title" style="margin-top: 10vh">
            <div class="allneibox" :class="activeIndex==8?'animate__animated animate__backInDown':''">
              <div>多维度神经心理测验</div>
              <div class="studyAdhdborder"></div>
            </div>
          </div>
          <div class="allneibox">
            <div class="product9Text" :class="activeIndex==8?'animate__animated animate__flipInX':''">
              <div v-html="homeItem9.typeInfo"></div>
            </div>
            <div class="product9TextBack"  :style="{backgroundImage:'url('+homeItem9.typePic+')'}" :class="activeIndex==8?'animate__animated animate__zoomInUp':''"></div>
          </div>
        </div>
        <div class="swiper-slide" id="product10">
          <div class="product10Back1"></div>
          <div class="product10Back2"></div>
          <div class="allneibox allflex product10box">
            <div :class="activeIndex==9?'animate__animated animate__backInDown':''" class="product10left">
              <div class="product10left1">
                儿童专属正念
              </div>
              <div class="product10left2">
                <div v-html="homeItem10.typeInfo"></div>
              </div>
              <div class="product10left3"></div>
            </div>
            <div :class="activeIndex==9?'animate__animated animate__backInUp':''" class="product10right" :style="{backgroundImage:'url('+homeItem10.typePic+')'}"></div>
          </div>
        </div>
        <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge11+')'}" id="product11">
          <div class="product11_1" :style="{backgroundImage:'url('+homeItem11.typePic+')'}" :class="activeIndex==10?'animate__animated animate__bounceInLeft':''"></div>
          <div :class="activeIndex==10?'animate__animated animate__bounceInRight':''" class="product11_3">
            <div class="product10left1" style="color: #FFFFFF">
              居家干预训练
            </div>
            <div class="product10left2" style="color: #FFFFFF;line-height: 2;font-size: 0.8vw;margin-top: 5vh">
              <div v-html="homeItem11.typeInfo"></div>
            </div>
            <div class="product10left3"></div>
          </div>
        </div>
        <div class="swiper-slide" id="product12">
          <div class="product12back"></div>
          <div class="allneibox allflex product12box">
            <div class="product12left" :class="activeIndex==11?'animate__animated animate__bounceInLeft':''"></div>
            <div class="product12right" :class="activeIndex==11?'animate__animated animate__bounceInRight':''">
              <div class="product12right1">
                家/校目标行为干预数字化工具
              </div>
              <div class="product12right2">
                <div class="product12right2_1"></div>
                <div>BQBrain算法智能推送个体化问题行为</div>
              </div>
              <div class="product12right2">
                <div class="product12right2_2"></div>
                <div>聚焦3-5个问题，建立家/校行为报告表</div>
              </div>
              <div class="product12right2">
                <div class="product12right2_3"></div>
                <div>每日1分钟记录</div>
              </div>
              <div class="product12right2">
                <div class="product12right2_4"></div>
                <div>问题行为阶段改善趋势线</div>
              </div>
              <div class="product12right3"></div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="product13">
          <div class="product13Back"></div>
          <div class="allneibox allflex product12box">
            <div class="product13box" :class="activeIndex==12?'animate__animated animate__bounceInLeft':''">
              <div class="product13box1_1"></div>
              <div class="product13box2">为专业人士提供</div>
              <div class="product13box3">专业人士产品与解决方案入口</div>
              <div class="product13box4"></div>
            </div>
            <div class="product13box" :class="activeIndex==12?'animate__animated animate__bounceInDown':''">
              <div class="product13box1_2"></div>
              <div class="product13box2">为教师提供</div>
              <div class="product13box3">教师产品与解决方案入口</div>
              <div class="product13box4"></div>

            </div>
            <div class="product13box" :class="activeIndex==12?'animate__animated animate__bounceInRight':''">
              <div class="product13box1_3"></div>
              <div class="product13box2">为家长提供</div>
              <div class="product13box3">家长产品与解决方案入口</div>
              <div class="product13box4"></div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="product14">
          <div class="studyAdhd11">
            <div class="studyAdhd11box">
              <div class="studyAdhdbox" style="display: flex;justify-content: space-between">
                <div class="studyAdhd11boxleft" :class="activeIndex==13?'animate__animated animate__fadeInLeftBig':''">
                  <div class="studyAdhd11boxleft1"></div>
                  <div class="studyAdhd11boxleft2">
                    <div>中国（湖南）自由贸易试验区长沙片区长沙经开区区块人民东路二段166号海凭医疗器械产业园3#栋1206、1207室</div>
                    <div>13337816281</div>
                    <div>wuxia@bqbrain.com</div>
                  </div>
                </div>
                <div class="studyAdhd11boxright"
                     :class="activeIndex==13?'animate__animated animate__fadeInRightBig':''">
                  <div class="studyAdhd11_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">了解ADHD</div>
                    <div>什么是注意力缺陷多动障碍</div>
                    <div>ADHD的数据和统计</div>
                    <div>诊断注意力缺陷多动障碍</div>
                    <div>注意力缺陷多动障碍的治疗</div>
                    <div>补充治疗</div>
                    <div>注意力缺陷多动障碍的共患病</div>
                    <div>常见问题</div>
                    <div>ADHD的误解</div>
                    <div>前沿</div>
                  </div>
                  <div class="studyAdhd11_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">产品&解决方案</div>
                    <div>一键式海筛工具</div>
                    <div>医教家全面测评量表</div>
                    <div>精准用药</div>
                    <div>多维度神经心理测验</div>
                    <div>注意力测评及训练</div>
                    <div>家/校目标行为干预数字化工具</div>
                    <div>儿童专属正念</div>
                    <div>真实世界研究工具</div>
                    <div>家长培训课程</div>
                    <div>居家感与训练</div>
                    <div>IEP项目</div>
                  </div>
                  <div class="studyAdhd11_boxbox" style="width: 230px;">
                    <div style="font-size: 36px;font-weight: bold;">宣传&教育</div>
                    <div>ADHDNews</div>
                    <div>ADHD月刊</div>
                    <div>培训与活动</div>
                    <div>客户故事</div>
                    <div>播客</div>
                    <div style="font-size: 36px;margin-top: 50px;font-weight: bold;">加入皕启</div>
                    <div>皕启脑科学</div>
                    <div>招聘岗位</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="studyAdhdfoother" :class="activeIndex==13?'animate__animated animate__backInUp':''">
              <div class="studyAdhdbox"
                   style="display: flex;justify-content: center;flex-wrap: wrap;color: #FFFFFF;align-items: center;height: 100%;">
                <div >
                  湖南皕启科技有限公司 © 2023 备案号:
                  <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #FFFFFF;text-decoration:none">湘ICP备2024066802号-1</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import {websiteApiType} from "@/api/api";

export default {
  data() {
    return {
      swiperOption: {},
      activeIndex: 0,
      homeImge1:"",
      homeImge2:"",
      homeImge3:"",
      homeImge4:"",
      homeImge6:"",
      homeImge7:"",
      homeImge8:"",
      homeImge9:"",
      homeImge11:"",
      contentData:"",
      homeItem1:"",
      homeItem2:"",
      homeItem3:"",
      homeItem4:"",
      homeItem5:"",
      homeItem6:"",
      homeItem7:"",
      homeItem8:"",
      homeItem9:"",
      homeItem10:"",
      homeItem11:"",
    };
  },
  created() {
    this.$parent.changeTitleMenu(0)
  },
  computed: {},
  mounted() {
    this.getSwiper()
    this.getAllData()
    this.getContData()
  },
  methods: {
    getContData(){
      let that = this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 8,
      }
      websiteApiType(data).then(res => {
        that.contentData = res.data.data.records
        for (let i = 0; i < this.contentData.length; i++) {
          if (this.contentData[i].typeName == '产品与解决方案') {
            this.homeItem1 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '一键式海筛工具') {
            this.homeItem2 =this.contentData[i]
            this.homeItem2.remark = JSON.parse(this.contentData[i].remark)
            this.homeItem2.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == '注意力训练') {
            this.homeItem3 =this.contentData[i]
            this.homeItem3.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == '数字化ADHD诊疗平台') {
            this.homeItem5 =this.contentData[i]
          }
          if (this.contentData[i].typeName == 'ADHD二十年lookingback') {
            this.homeItem6 =this.contentData[i]
            let parmesData = JSON.parse(this.contentData[i].remark)
            this.homeItem6.remark = parmesData.map(item=>{
              return {
                ...item,
                nameData:item.name.split('-')
              }
            })
          }
          if (this.contentData[i].typeName == '多场景全角色测评') {
            this.homeItem7 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '医教家全面测评') {
            this.homeItem8 =this.contentData[i]
            this.homeItem8.remark = JSON.parse(this.contentData[i].remark)
          }
          if (this.contentData[i].typeName == '多维度神经心理测验') {
            this.homeItem9 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '儿童专属正念') {
            this.homeItem10 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '居家干预训练') {
            this.homeItem11 =this.contentData[i]
          }
        }
      })
    },
    getAllData() {
      let that =this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 3,
      }
      websiteApiType(data).then(res => {
        let imgData = res.data.data.records
        for (let i = 0; i < imgData.length; i++) {
          let nameType = imgData[i].typeName
          if (nameType == "模块1") {
            that.homeImge1 = imgData[i].typePic;
          }
          if (nameType == "模块2") {
            that.homeImge2 = imgData[i].typePic;
          }
          if (nameType == "模块3") {
            that.homeImge3 = imgData[i].typePic;
          }
          if (nameType == "模块4") {
            that.homeImge4 = imgData[i].typePic;
          }
          if (nameType == "模块6") {
            that.homeImge6 = imgData[i].typePic;
          }
           if (nameType == "模块7") {
            that.homeImge7 = imgData[i].typePic;
          }
           if (nameType == "模块8") {
            that.homeImge8 = imgData[i].typePic;
          }
           if (nameType == "模块9") {
            that.homeImge9 = imgData[i].typePic;
          }
           if (nameType == "模块11") {
            that.homeImge11 = imgData[i].typePic;
          }

        }
      }).catch(err => console.log(err))
    },
    changewi() {
      this.swiperOption.slideTo(0);
    },
    getSwiper() {
      let that = this
      this.swiperOption = new Swiper(".swiper-container", {
        direction: 'vertical',
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          touchStart: function (e) {
            // 打印当前索引
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
          slideChange: function (e) {
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
        },
      })
    }
  }
};
</script>

<style scoped>
.product6BT1 {
  left: 19%;
  top: 32%;

}

.product6BT2 {
  left: 33%;
  top: 35%;

}

.product6BT3 {
  left: 50%;
  top: 24%;

}

.product6BT4 {
  left: 66%;
  top: 16%;

}

.product6BT5 {
  left: 81%;
  top: 10%;
}

.product6BackTitle {
  position: absolute;
}

.product6BackTitle1 {
  font-size: 1.6vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}

.product6BackTitle2 {
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #15233B;
  margin-top: 2.7vh;
}

.product6Back {
  background-image: url("@/static/product/product6Back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 75vw;
  height: 70.64vh;
  top: 3.5vh;
  left: 12.5%;
  right: 12.5%;
  position: absolute;
}

.product6Back1 {
  bottom: 8%;
  left: 12.5%;
}

.product6Back2 {
  bottom: 2%;
  left: 28.5%;
  width: 15vw !important;
}

.product6Back4 {
  bottom: 17%;
  left: 45.5%;
  width: 15vw !important;
}

.product6Back3 {
  bottom: 4%;
  left: 62.5%;
  width: 13vw !important;
}

.product6Back5 {
  bottom: 34%;
  left: 78.5%;
  width: 13vw !important;
}

.product6Backtext {
  width: 11.7vw;
  line-height: 2;
  font-size: 0.8vw;
  font-weight: 400;
  color: #15233B;
  position: absolute;
  font-family: none;
}

.product4Back {
  background-image: url("@/static/product/product4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 70.31vw;
  height: 84vh;
  left: 13vw;
  top: 13vh;
  position: absolute;
}

.product7right {
  background-image: url("@/static/product/product7right.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 34.65vw;
  height: 34.81vh;
  right: 12.5vw;
  top: 30.65vh;
  position: absolute;
  box-sizing: border-box;
  padding: 0vw 2.4vw 0vw;
}

.product7rightTitle {
  font-size: 1.145vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
  margin-top: 5.6vh;
  width: 100%;
  text-align: right;
}

.product7rightcenter {
  margin-top: 2.3vh;
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #15233B;
}

#product14 {
  width: 100%;
  position: relative;
}

.studyAdhd11box {
  background-image: url("@/static/studyAdhd/studyAdhd11.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 70vh;
}

.studyAdhdfoother {
  width: 100%;
  height: 10vh;
  background: #363636;
}

.studyAdhd11boxleft1 {
  background-image: url("@/static/studyAdhd/studyAdhd11boxleft1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.69vw;
  height: 9.7vh;
}

.studyAdhdbox {
  width: 75vw;
  margin: auto;
  position: relative;
}

.studyAdhd11boxleft2 {
  font-size: 0.6vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  margin-top: 3vh;
}

.studyAdhd11 {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.studyAdhd11boxright {
  display: flex;
  justify-content: space-between;
  width: 44vw;
  line-height: 3;
}

.product9TextBack {
  background-image: url("@/static/product/product9TextBack.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 33.75vw;
  height: 44.9vh;
  margin: auto;
}

.product9Text {
  font-size: 0.7vw;
  text-align: center;
  margin-top: 50px;
  line-height: 2;
}

.product5back {
  background-image: url("@/static/product/product5back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 22.81vw;
  height: 23.81vw;
  position: absolute;
  right: 0;
  bottom: 0;
  animation: shake 3s linear infinite;
}

@keyframes shake {
  0% {
    margin-top: 0;
    transform: rotate(0deg);
  }
  60% {
    margin-top: 14px;
    transform: rotate(5deg);
  }
  100% {
    margin-top: 0;
    transform: rotate(0deg);
  }
}

.product5fother {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 39.27vw;
  height: 55.46vh;
  margin: auto;
  margin-top: 2vh;
}

.product2_3 {
  display: flex;
  justify-items: center;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  float: right;
  margin-top: 10vh;
}

.product2box {
  width: 16.7vw;
  height: 24vh;
  text-align: center;
}

.product2box1 {
  background-image: url("@/static/product/product2box1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
}

@keyframes myfirstb {
  0% {
    margin-top: 0;
  }
  60% {
    margin-top: 8px;
  }
  100% {
    margin-top: 0;
  }
}

.product2box2 {
  background-image: url("@/static/product/product2box2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
}

.product2box3 {
  background-image: url("@/static/product/product2box3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
}

.product2box4 {
  background-image: url("@/static/product/product2box4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
}

.product2box5 {
  background-image: url("@/static/product/product2box5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
}

.product2box6 {
  background-image: url("@/static/product/product2box6.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
}

.product2box7 {
  background-image: url("@/static/product/product2box7.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
}

.product2boxtext {
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  margin-top: 2vh;
}

#product1, #product2, #product3, #product4, #product5, #product6, #product7, #product8, #product9, #product10, #product11, #product12, #product13, #product14 {
  position: relative;
  width: 100%;
  height: 100%;
}

.studyAdhd3Title {
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
  margin-top: 16.85vh;
}

.studyAdhdborder {
  width: 11.25vw;
  height: 2px;
  margin-top: 5vh;
  background: linear-gradient(135deg, #005DFF 0%, #55FDF4 100%);
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  right: 0 !important;
  left: 3% !important;
  width: 50px !important;
}

.swiper-pagination >>> .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  border: 0;
  margin: 10px 0 0 10px;
  border-radius: 50%;
  background: #005dff;
}

.swiper-pagination >>> .swiper-pagination-bullet-active {
  margin: 10px 0 0 0.3vw;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: linear-gradient(180deg, #55FDF4 0%, #005DFF 100%);
}

.product {
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  overflow: hidden;
}

#product1 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#product3 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

#product6 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#product6 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#product7 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#product8 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#product9 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#product11 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.product1left {
  background-image: url("@/static/product/product1left.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 48vw;
  height: 63.24vh;
  left: 3.4vw;
  bottom: 11.48vh;
  position: absolute;
}

.product1right {
  width: 35vw;
  height: 63.24vh;
  right: 12.55vw;
  bottom: 11.48vh;
  position: absolute;
}

.product1right1 {
  font-family: SourceKeynoteartHans;
  font-size: 4.3vw;
  font-weight: 400;
  color: #15233B;
}

.product1right2 {
  width: 90%;
  margin-top: 6vh;
  line-height: 2;
}
.product1right2item{
  width: 100%;
  display: flex;
  margin-top: 5vh;
  justify-content: space-between;
}

.product2_1 {
  background-image: url("@/static/product/product2_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 14.27vw;
  height: 20.83vh;
  position: absolute;
  right: 0;
  top: 34px;

}

.product2_2 {
  background-image: url("@/static/product/product2_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 39.1vw;
  height: 26vh;
  bottom: 0;
  left: 0;
  position: absolute;
}

.product3left1 {
  background-image: url("@/static/product/product3left1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 43.64vw;
  height: 70vh;
  position: absolute;
  left: 0;
  bottom: 0;
}

.product3left2 {
  background-image: url("@/static/product/product3left2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 36.7vw;
  height: 51vh;
  position: absolute;
  left: 3vw;
  bottom: 26.29vh;
}

.product3right {
  width: 35vw;
  height: 70vh;
  position: absolute;
  right: 0;
  bottom: 0;
}

.product3rightText {
  font-size: 1vw;
  line-height: 2;
  margin-top: 5vh;
}

.product3rightfotther {
  background-image: url("@/static/product/product3rightfotther.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.64vw;
  height: 7vh;
  position: absolute;
  right: 0;
  bottom: 20vh;
}

.product4_1 {
  background-image: url("@/static/product/product4_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.2vw;
  height: 8.2vw;
  position: absolute;
  left: 10.8vw;
  top: 30.5vh;
  animation: myfirstb linear infinite 2s;

}

.product4_2 {
  background-image: url("@/static/product/product4_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.2vw;
  height: 8.2vw;
  position: absolute;
  left: 18.6vw;
  top: 46.9vh;
  animation: myfirstb linear infinite 2s;

}

.product4_3 {
  background-image: url("@/static/product/product4_3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 6.97vw;
  height: 6.97vw;
  position: absolute;
  left: 19.1vw;
  top: 80vh;
  animation: myfirstb linear infinite 2s;

}

.product4_4 {
  background-image: url("@/static/product/product4_4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 6.97vw;
  height: 6.97vw;
  position: absolute;
  right: 19.1vw;
  top: 38.79vh;
  animation: myfirstb linear infinite 2s;

}

.product4_5 {
  background-image: url("@/static/product/product4_5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.2vw;
  height: 8.2vw;
  position: absolute;
  right: 12.5vw;
  animation: myfirstb linear infinite 2s;

  top: 50.55vh;
}

.product4_6 {
  background-image: url("@/static/product/product4_6.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.2vw;
  height: 8.2vw;
  position: absolute;
  right: 23.59vw;
  top: 75vh;
  animation: myfirstb linear infinite 2s;
}

.product4box {
  width: 20.8vw;
  margin: auto;
  text-align: center;
}

.product4box1 {
  font-size: 1.8vw;
  margin-top: 26vh;
  font-weight: bold;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}

.product4box2 {
  width: 20.8vw;
  line-height: 2;
  margin-top: 8vh;
  font-size: 0.8vw;
}

.product5Text {
  font-size: 0.8vw;
  color: black;
  font-family: Algerian;
  line-height: 2;
  margin-top: 3vh;
}

.product7Back1 {
  background-image: url("@/static/product/product7Back1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 15.67vw;
  height: 21.85vh;
  position: absolute;
  left: 0;
  top: 20px;
  animation: myfirstb2 linear infinite 2s;
}

.product7Back2 {
  background-image: url("@/static/product/product7Back2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.48vw;
  height: 19.44vh;
  position: absolute;
  right: 43px;
  top: 70vh;
  animation: myfirstb2 linear infinite 2s;
}

@keyframes myfirstb2 {
  0% {
    margin-top: 0;
  }
  60% {
    margin-top: 13px;
  }
  100% {
    margin-top: 0;
  }
}

.product7diqiu {
  width: 42.5vw;
  height: 42.5vw;
  top: 10vh;
  left: 10vw;
  position: absolute;
}

.product7diqiu1 {
  background-image: url("@/static/product/diqiu.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 42.5vw;
  height: 42.5vw;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
}

.product7diqiu2 {
  background-image: url("@/static/product/diqiu2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  width: 42.5vw;
  height: 42.5vw;
  position: absolute;
  left: 0;
  top: 0;
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}

.product8Back {
  width: 83.43vw;
  height: 52vh;
  background-image: url("@/static/product/product8Back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 4.11vw;
  bottom: 4.7vh;
}

.product8Backbox1 {
  width: 25%;
  height: 25vh;
}

.product9Back1 {
  width: 9.63vw;
  height: 21vh;
  background-image: url("@/static/product/product9Back1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0;
  top: 0;
}

.product9Back2 {
  width: 11.61vw;
  height: 25vh;
  background-image: url("@/static/product/product9Back2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  bottom: 8vh;
}

.product10Back1 {
  width: 15vw;
  height: 20.83vh;
  background-image: url("@/static/product/product10Back1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

.product10Back2 {
  width: 12.5vw;
  height: 6.66vh;
  background-image: url("@/static/product/product10Back2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0;
  bottom: 8.3vh;
}

.product10left {
  width: 28.83vw;
}

.product10left1 {
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}

.product10left2 {
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #15233B;
  line-height: 3;
}

.product10left3 {
  margin-top: 13.7vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}

.product10right {
  background-image: url("@/static/product/product10right.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 41vw;
  height: 43.24vh;
}

.product10box {
  margin-top: 22vh;
}

.product11_1 {
  background-image: url("@/static/product/product11_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 40.20vw;
  height: 49vh;
  position: absolute;
  top: 26.57vh;
  left: 12.45vw;
}

.product11_2 {
  background-image: url("@/static/product/product11_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 18.95vw;
  height: 26.94vh;
  position: absolute;
  top: 26.57vh;
  left: 31.59vw;
}

.product11_3 {
  width: 29.73vw;
  height: 48.88vh;
  position: absolute;
  top: 26.48vh;
  right: 12.5vw;
}

.product12back {
  background-image: url("@/static/product/product12back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 10.41vw;
  height: 22.03vh;
  position: absolute;
  top: 0vh;
  right: 0vw;
}

.product12left {
  background-image: url("@/static/product/product12left.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 38.28vw;
  height: 52vh;
}

.product12right {
  width: 24.63vw;
}

.product12right1 {
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}

.product12right2 {
  width: 24.63vw;
  display: flex;
  justify-content: flex-start;
  font-size: 0.8vw;
  flex-wrap: wrap;
  align-items: center;
}

.product12right2_1 {
  background-image: url("@/static/product/product12right2_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.55vw;
  height: 2.55vw;
}

.product12right2_2 {
  background-image: url("@/static/product/product12right2_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.55vw;
  height: 2.55vw;
}

.product12right2_3 {
  background-image: url("@/static/product/product12right2_3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.55vw;
  height: 2.55vw;
}

.product12right2_4 {
  background-image: url("@/static/product/product12right2_4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.55vw;
  height: 2.55vw;
}

.product12right3 {
  margin-top: 3vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}

.product12box {
  margin-top: 22vh;
  line-height: 5;
}

.product13Back {
  background-image: url("@/static/product/product13back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 10.65vw;
  height: 22.83vh;
  position: absolute;
  top: 20.3vw;
  left: 0vw;
}

.product13box {
  width: 19.375vw;
  height: 48.88vh;
  text-align: center;
}

.product13box1_1 {
  width: 19.375vw;
  height: 28.98vh;
  background-image: url("@/static/product/product13box1_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.product13box1_2 {
  width: 19.375vw;
  height: 28.98vh;
  background-image: url("@/static/product/product13box1_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.product13box1_3 {
  width: 19.375vw;
  height: 28.98vh;
  background-image: url("@/static/product/product13box1_3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.product13box2 {
  font-size: 1.4vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #005DFF;
}

.product13box3 {
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #15233B;
}

.product13box4 {
  margin-top: 3vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
  margin: auto;
}
</style>
<style>
@keyframes animtran {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes animtrans {
  from {
    transform: rotateZ(360deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}

#advert {
  width: 15.625vw;
  height: 15.625vw;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

#advert .bg1 {
  width: 13.1vw;
  height: 13.1vw;
  position: absolute;
  top: 1.15vw;
  left:1.15vw;
  z-index: 10;
}

#advert .bg1 div {
  width: 13.1vw;
  height: 13.1vw;
  border: rgba(0, 93, 255, 1) 1px solid;
  border-radius: 15em;
  position: relative;
  animation: animtran 16s linear infinite;
  -webkit-animation: animtran 16s linear infinite;
}

#advert .bg1 img {
  width: 2vw;
  position: absolute;
  animation: animtrans 16s linear infinite;
  -webkit-animation: animtrans 16s linear infinite;
}

#advert .bg1 .icon01 {
  bottom: 1.3vw;
  left: 0.3vw;
}

#advert .bg1 .icon02 {
  top: 4.16vw;
  left: -1.25vw;
}

#advert .bg1 .icon03 {
  top: -0.3vw;
  left: 2.29vw;
}

#advert .bg1 .icon04 {
  top: -0.3vw;
  right: 2.29vw;
}

#advert .bg1 .icon05 {
  top: 4.16vw;
  right: -1.25vw;
}

#advert .bg1 .icon06 {
  bottom: 1.3vw;
  right: 0.3vw;
}

#advert .bg1 .icon07 {
  bottom: -1.3vw;
  left: 5.468vw;
}

#advert .bg2 {
  width: 14.16vw;
  height: 14.16vw;
  position: absolute;
  top: 3.1vw;
  left: 2.9vw;
  z-index: 9;
}

#advert .bg2 div {
  width: 9.5vw;
  height: 9.5vw;
  border: rgba(0, 93, 255, 1) 1px solid;
  border-radius: 15em;
  position: relative;
  animation: animtrans 12s linear infinite;
  -webkit-animation: animtrans 12s linear infinite;
}

#advert .bg2 span {
  display: inline-block;
  overflow: hidden;
  border-radius: 15em;
  position: absolute;
  animation: animtran 6s linear infinite;
  -webkit-animation: animtran 6s linear infinite;
}

#advert .bg2 img {
  padding: 10px;
  display: block;
}

#advert .bg2 .icon08 {
  top: 0px;
  left: 6px;
}

#advert .bg2 .icon09 {
  bottom: 0;
  left: 0.3vw;
}

#advert .bg2 .icon10 {
  bottom: 15px;
  right: -15px;
}


#advert .bg3 {
  width: 5.208vw;
  height: 5.208vw;
  position: absolute;
  top: 5.208vw;
  left: 5.208vw;
  z-index: 8;
}

#advert .bg3 div {
  width: 5.208vw;
  height: 5.208vw;
  border: rgba(0, 93, 255, 1) 1px dotted;
  border-radius: 15em;
  position: relative;
  animation: animtran 8s linear infinite;
  -webkit-animation: animtran 8s linear infinite;
}

#advert .bg3 span {
  display: inline-block;
  overflow: hidden;
  border-radius: 15em;
  position: absolute;
  animation: animtrans 4s linear infinite;
  -webkit-animation: animtrans 4s linear infinite;
}

#advert .bg3 img {
  padding: 10px;
  display: block;
}

#advert .bg3 .icon11 {
  top: -6px;
  right: 0px;
}
.icon11boder{
  border-radius: 50%;
  border: 1px solid rgba(0, 93, 255, 1);
  width: 0.8vw;
  height: 0.8vw;
  background: #e1f8fe;
}
#advert .bg3 .icon12 {
  bottom: -13px;
  right: 10px;
}

#advert .content {
  width: 8.38vw;
  height: 2.55vw;
  background: linear-gradient(135deg, #005DFF 0%, #55FDF4 100%);
  box-shadow: 0px 3px 9px 0px rgba(36, 142, 214, 0.73);
  border-radius: 26px;
  position: absolute;
  z-index: 11;
  color: #fff;
  font-size: 20px;
  display: flex;
  box-sizing: border-box;
  padding: 0px 18px 0px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}


@keyframes ani-one {
  from {
    left: 13.1vw;
  }
  to {
    left: 16px
  }
}

@keyframes ani-two {
  from {
    left: 13.1vw;
  }
  to {
    left: 70px
  }
}

@keyframes ani-three {
  from {
    top: 0;
    left: 0;
  }
  to {
    top: 8px;
    left: 8px;
  }
}

@keyframes ani-four {
  0% {
    width: 6.77vw;
    height: 2.6vw;
    left:4.427vw;
    top: 11.3vw;
    box-shadow: #f6f6f6 8px 8px 8px 4px;
    border: #eee 0px solid;
    margin-left: 0;
  }
  100% {
    width: 13.48vw;
    height: 17.34vw;
    left: 50%;
    top: 0px;
    box-shadow: #FFFFFF 0 0 0 0;
    border: #eee 1px solid;
    margin-left: -6.77vw;
  }
}

@keyframes ani-five {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes ani-six {
  from {
    left: 13.1vw;
  }
  to {
    left: 0;
  }
}


</style>
