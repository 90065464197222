import Vue from 'vue'
import VueRouter from 'vue-router'
// 主页面
import home from '../views/home.vue'
// 加入我们
import joinUs from '../views/joinUs/index.vue'
// 产品&解决方案
import product from '../views/product/index.vue'
// 宣传教育
import publicize from '../views/publicize/index.vue'
// 了解ADHD
import studyAdhd from '../views/studyAdhd/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component:joinUs
  },
  {
    path: '/product',
    name: 'product',
    component:product
  },
  {
    path: '/publicize',
    name: 'publicize',
    component:publicize
  },
  {
    path: '/studyAdhd',
    name: 'studyAdhd',
    component:studyAdhd
  },
]

const router = new VueRouter({
  routes
})

export default router
