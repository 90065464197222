<template>
	<div id="app">
		<div class="menuTitle" :style="{'background':swapperindex==0?'transparent':'#ffffff'}" :class="swapperindex==0||isshow==1?'animate__animated animate__bounceInDown':'animate__animated animate__backOutUp'">
			<div class="menuTitleBox">
				<div>
					<img src="@/static/logo.png" draggable="false" style="width: 92px;height: 36px;" />
				</div>
				<div class="menuTitleText">
					<router-link :to="{name:'home'}" class="menuTitleTextClick" :class="isPath==0?'menuTitleText2':''"
						@click.native="menuCLick(0)">首页</router-link>
					<router-link :to="{name:'studyAdhd'}" class="menuTitleTextClick" @click.native="menuCLick(1)"
						:class="isPath==1?'menuTitleText2':''">了解ADHD</router-link>
					<router-link :to="{name:'product'}" class="menuTitleTextClick" @click.native="menuCLick(2)"
						:class="isPath==2?'menuTitleText2':''">产品&解决方案</router-link>
					<router-link :to="{name:'publicize'}" class="menuTitleTextClick" @click.native="menuCLick(3)"
						:class="isPath==3?'menuTitleText2':''">宣传&教育</router-link>
					<router-link :to="{name:'joinUs'}" class="menuTitleTextClick" @click.native="menuCLick(4)"
						:class="isPath==4?'menuTitleText2':''">加入皕启</router-link>
				</div>
			</div>
		</div>
		<div class="menuLatiao"  @click="seeTitleMenu" :class="swapperindex!==0&&isshow==0?'animate__animated animate__bounceInDown':'animate__animated animate__backOutUp'">
			<img src="@/static/menu2.png" draggable="false" style="width: 100%;" />
		</div>
		<div class="backTitle" v-if="swapperindex!==0">
			<div @click="goTitleMenu">
				<img src="@/static/backTitle.png" draggable="false" style="width: 61px;height: 73px;"/>
			</div>
			<div>
				返回顶部
			</div>
		</div>
		<router-view ref="childClick"/>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isPath: 0,
				pagePath: "",
				isshow: 1,
				swapperindex: 0
			};
		},
		computed: {},
		created() {
			this.getMenu()
		},
		mounted() {},
		methods: {
			goTitleMenu(){
				this.$refs.childClick.changewi()
			},
			seeTitleMenu(){
				this.isshow = 1
			},
			changeTitleMenu(item){
				this.swapperindex = item
				this.isshow = 0
			},
			getMenu() {
				if (this.$route.name == 'home') {
					this.isPath = 0
				}
				if (this.$route.name == 'studyAdhd') {
					this.isPath = 1
				}
				if (this.$route.name == 'product') {
					this.isPath = 2
				}
				if (this.$route.name == 'publicize') {
					this.isPath = 3
				}
				if (this.$route.name == 'joinUs') {
					this.isPath = 4
				}
			},
			menuCLick(item) {
				this.isPath = item
			}
		}
	}
</script>
<style>
	body {
		padding: 0;
		margin: 0;
    cursor: url("static/mouseHove.png"), auto;
	}
	#app{
		overflow: hidden;
	}

  .allneibox{
    width: 75vw;
    margin: auto;
    position: relative;
  }
  .allflex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  @font-face {
    font-family: 'SourceKeynoteartHans';
    src: url('@/static/Source-KeynoteartHans.ttf');
  }
	.backTitle{
		width: 61px;
		position: absolute;
		z-index: 99;
		right: 4vw;
		bottom: 8vh;
		font-size: 14px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #005DFF;
	}
	.menuLatiao {
		width: 103px;
		height: 134px;
		position: absolute;
		z-index: 99;
		right: 8vw;
		cursor: pointer;
	}

	.menuLatiao {
		width: 103px;
		height: 134px;
		position: absolute;
		z-index: 99;
		right: 8vw;
		top: -30px;
		cursor: pointer;
	}

	.menuLatiao:active {
		animation: jumpBoxHandler 1.8s infinite;
	}

	@keyframes jumpBoxHandler {
		0% {
			transform: translate(0px, 0px);
		}

		30% {
			transform: translate(0px, 15px);
		}
		30% {
			transform: translate(0px, 30px);
		}
		100% {
			transform: translate(0px, 0px);
		}
	}

	.menuTitleTextClick {
		height: 30px;
		text-decoration: none;
		color: rgba(0, 93, 255, 0.5);
		cursor: url("static/mouseHove.png"), auto;
	}

	.menuTitleTextClick:hover {
		font-size: 15px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: rgba(0, 93, 255);
		border-bottom: 3px solid rgba(0, 93, 255);
	}

	.menuTitleText {
		font-size: 15px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: rgba(0, 93, 255, 0.5);
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		width: 600px;
	}

	.menuTitleText2 {
		font-size: 15px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: rgba(0, 93, 255);
		border-bottom: 3px solid rgba(0, 93, 255);
		text-decoration: none;
	}

	.menuTitle {
		width: 100vw;
		height: 90px;
		position: absolute;
		top: 0;
		z-index: 999;
	}

	.menuTitleBox {
		width: 80%;
		margin: auto;
		align-items: center;
		height: 100%;
		display: flex;
		justify-content: space-between;
	}
</style>
