<template>
  <div class="home">
    <!-- 	<div class="ceshititle" v-if="activeIndex!==0&&isshow==0" @click="changezhan"></div>
      <div class="HomeTitle" :style="{'background':activeIndex==0?'transparent':'#ffffff'}"  :class="activeIndex==0||isshow==1?'animate__animated animate__bounceInDown':'animate__animated animate__backOutUp'">
        <div class="HomeTitleBox">
          <div>
            <img src="../static/logo.png" style="width: 92px;height: 36px;" />
          </div>
          <div class="HomeTitleText">
            <div class="HomeTitleTextClick" :class="isPath==0?'HomeTitleText2':''">首页</div>
            <div class="HomeTitleTextClick" @click="changewi">了解ADHD</div>
            <div class="HomeTitleTextClick">产品&解决方案</div>
            <div class="HomeTitleTextClick">宣传&教育</div>
            <div class="HomeTitleTextClick">加入皕启</div>
          </div>
        </div>

      </div> -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" id="homePage1" :style="{backgroundImage:'url('+homeImge1+')'}">
          <div style="width: 100%;height: 100%;position: relative;">
            <img :src="homeItem1.typePic" class="box1_1"
                 :class="activeIndex==0?'animate__animated animate__bounceInDown':'animate__animated animate__backOutUp'"/>
            <div class="box1_2">
              <img src="../static/box1_2.png" class="box1_2Imge"/>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="homePage2" :style="{backgroundImage:'url('+homeImge2+')'}">
          <div class="homebox" :class="activeIndex==1?'animate__animated animate__zoomInDown':''">
            <div class="homebox2">
              <div class="homebox2_box" v-if="homeItem2jzrk.typePic">
                <div class="homebox2_box_img">
                  <img :src="homeItem2jzrk.typePic[0]"/>
                </div>
                <div class="homebox2_box_Text">
                  家长入口
                </div>
                <div class="homebox2Back1 homebox2Backs" :style="{backgroundImage:'url('+homeItem2jzrk.typePic[1]+')'}"></div>
              </div>
              <div class="homebox2_box" v-if="homeItem2zyrs.typePic">
                <div class="homebox2_box_img">
                  <img :src="homeItem2zyrs.typePic[0]"/>
                </div>
                <div class="homebox2_box_Text">
                  专业人士入口
                </div>
                <div class="homebox2Back2 homebox2Backs" :style="{backgroundImage:'url('+homeItem2zyrs.typePic[1]+')'}"></div>
              </div>
              <div class="homebox2_box" v-if="homeItem2jsrl.typePic">
                <div class="homebox2_box_img">
                  <img :src="homeItem2jsrl.typePic[0]"/>
                </div>
                <div class="homebox2_box_Text">
                  教师入口
                </div>
                <div class="homebox2Back3 homebox2Backs" :style="{backgroundImage:'url('+homeItem2jsrl.typePic[1]+')'}"></div>
              </div>
              <div class="homebox2_box" v-if="homeItem2xsrk.typePic">
                <div class="homebox2_box_img">
                  <img :src="homeItem2xsrk.typePic[0]"/>
                </div>
                <div class="homebox2_box_Text">
                  学术入口
                </div>
                <div class="homebox2Back4 homebox2Backs" :style="{backgroundImage:'url('+homeItem2xsrk.typePic[1]+')'}"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="homePage3" :style="{backgroundImage:'url('+homeImge3+')'}">
          <div class="homebox">
            <div class="homebox3">
              <div class="homebox3_1"
                   :class="activeIndex==2?'animate__animated animate__fadeInDownBig':''">
                <img src="../static/homeBox3_1.png" style="width: 489px;height: 124px;"/>
              </div>
              <div class="homebox3_2" :class="activeIndex==2?'animate__animated animate__fadeInDownBig':''">
                <div v-html="homeItem3.typeInfo"></div>
              </div>
              <div class="homebox3_3" :class="activeIndex==2?'animate__animated animate__fadeInLeft':''"
                   v-if="homeItem3.remark">
                <div class="homebox3_31">
                  <div class="homebox3_311">
                    <img src="../static/homeBox3_21.png" style="width: 43px;height: 43px;"/>
                    <div style="font-size: 35px;">
                      {{ homeItem3.remark[0].name }}
                    </div>
                  </div>
                  <div class="homebox3_312">
                    {{ homeItem3.remark[0].content }}
                  </div>
                </div>
                <div class="homebox3_31">
                  <div class="homebox3_311">
                    <img src="../static/homeBox3_22.png" style="width: 43px;height: 43px;"/>
                    <div style="font-size: 35px;">
                      {{ homeItem3.remark[1].name }}
                    </div>
                  </div>
                  <div class="homebox3_312">
                    {{ homeItem3.remark[1].content }}
                  </div>
                </div>
                <div class="homebox3_31">
                  <div class="homebox3_311">
                    <img src="../static/homeBox3_23.png" style="width: 43px;height: 43px;"/>
                    <div style="font-size: 35px;">
                      {{ homeItem3.remark[2].name }}
                    </div>
                  </div>
                  <div class="homebox3_312">
                    {{ homeItem3.remark[2].content }}
                  </div>
                </div>
              </div>

              <div style="margin-top: 106px;display: flex;cursor: pointer;">
                <div>READ MORE</div>
                <img src="../static/back.png" style="width: 26px;height: 26px;"/>
              </div>
              <div class="homebox3Imge"
                   :class="activeIndex==2?'animate__animated animate__fadeInRightBig':''">
                <img src="../static/homeBox3_3.png" style="width: 100%;height: 100%;"/>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="homePage4" :style="{backgroundImage:'url('+homeImge4+')'}">
          <div class="homebox">
            <div class="homebox4">
              <div class="homebox4_1"
                   :class="activeIndex==3?'animate__animated animate__fadeInDownBig':''">
                <img src="../static/homeBox4_1.png" style="width: 509px;height: 94px;"/>
              </div>
              <div class="homebox4_2">
                <div class="homebox4_21"
                     :class="activeIndex==3?'animate__animated animate__fadeInLeftBig':''">
                  <img src="../static/homeBox4_2.png" style="width: 409px;height: 483px;"/>
                </div>
                <div class="homebox4_22"
                     :class="activeIndex==3?'animate__animated animate__fadeInRightBig':''">
                  <div
                      style="width: 106px;height: 5px;background: linear-gradient(314deg, #55FDF4 0%, #005DFF 100%);">
                  </div>
                  <div style="margin-top: 44px;width: 70%;">
                    <div v-html="homeItem4.typeInfo"></div>
                  </div>
                  <div style="margin-top: 51px;display: flex;cursor: pointer;">
                    <div>READ MORE</div>
                    <img src="../static/back.png" style="width: 26px;height: 26px;"/>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="homePage5" :style="{backgroundImage:'url('+homeImge5+')'}">
          <div class="homebox">
            <div class="homebox5">
              <div class="homebox5_1"
                   :class="activeIndex==4?'animate__animated animate__fadeInDownBig':''">
                <img src="../static/homeBox5_1.png" style="width: 804px;height: 98px;"/>
              </div>
              <div class="homebox5_2" v-if="homeItem5.remark"
                   :class="activeIndex==4?'animate__animated animate__fadeInRightBig':''">
                <div class="homebox5_2Box">
                  <div style="width: 76px;">
                    <div style="font-size: 36px;font-weight: bold;color: #00485E;">
                      {{ homeItem5.remark[2].month }}.{{ homeItem5.remark[2].day }}
                    </div>
                    <div style="font-size: 18px;font-weight: 400;color: #828B99;margin-top: 16px;">
                      {{ homeItem5.remark[0].year }}
                    </div>
                    <div
                        style="width: 76px;height: 2px;background: linear-gradient(314deg, #55FDF4 0%, #005DFF 100%);border-radius: 2px;margin-top: 40;">
                    </div>
                  </div>
                  <div style="width: 287px;">
                    <div style="font-size: 20px;font-weight: 400;color: #0E1828;">{{ homeItem5.remark[0].title }}</div>
                    <div style="font-size: 15px;font-weight: 400;color: #0E1828;margin-top: 20px;">
                      {{ homeItem5.remark[0].content }}
                    </div>
                  </div>
                </div>
                <div class="homebox5_2Box">
                  <div style="width: 76px;">
                    <div style="font-size: 36px;font-weight: bold;color: #00485E;">
                      {{ homeItem5.remark[2].month }}.{{ homeItem5.remark[2].day }}
                    </div>
                    <div style="font-size: 18px;font-weight: 400;color: #828B99;margin-top: 16px;">
                      {{ homeItem5.remark[1].year }}
                    </div>
                    <div
                        style="width: 76px;height: 2px;background: linear-gradient(314deg, #55FDF4 0%, #005DFF 100%);border-radius: 2px;margin-top: 40;">
                    </div>
                  </div>
                  <div style="width: 287px;">
                    <div style="font-size: 20px;font-weight: 400;color: #0E1828;">{{ homeItem5.remark[1].title }}</div>
                    <div style="font-size: 15px;font-weight: 400;color: #0E1828;margin-top: 20px;">
                      {{ homeItem5.remark[1].content }}
                    </div>
                  </div>
                </div>
                <div class="homebox5_2Box" style="margin-top: 50px;">
                  <div style="width: 76px;">
                    <div style="font-size: 36px;font-weight: bold;color: #00485E;">
                      {{ homeItem5.remark[2].month }}.{{ homeItem5.remark[2].day }}
                    </div>
                    <div style="font-size: 18px;font-weight: 400;color: #828B99;margin-top: 16px;">
                      {{ homeItem5.remark[2].year }}
                    </div>
                    <div
                        style="width: 76px;height: 2px;background: linear-gradient(314deg, #55FDF4 0%, #005DFF 100%);border-radius: 2px;margin-top: 40;">
                    </div>
                  </div>
                  <div style="width: 287px;">
                    <div style="font-size: 20px;font-weight: 400;color: #0E1828;">{{ homeItem5.remark[2].title }}</div>
                    <div style="font-size: 15px;font-weight: 400;color: #0E1828;margin-top: 20px;">
                      {{ homeItem5.remark[2].content }}
                    </div>
                  </div>
                </div>
                <div class="homebox5_2Box" style="margin-top: 50px;">
                  <div style="width: 76px;">
                    <div style="font-size: 36px;font-weight: bold;color: #00485E;">
                      {{ homeItem5.remark[2].month }}.{{ homeItem5.remark[2].day }}
                    </div>
                    <div style="font-size: 18px;font-weight: 400;color: #828B99;margin-top: 16px;">
                      {{ homeItem5.remark[3].year }}
                    </div>
                    <div
                        style="width: 76px;height: 2px;background: linear-gradient(314deg, #55FDF4 0%, #005DFF 100%);border-radius: 2px;margin-top: 40;">
                    </div>
                  </div>
                  <div style="width: 287px;">
                    <div style="font-size: 20px;font-weight: 400;color: #0E1828;">{{ homeItem5.remark[3].title }}</div>
                    <div style="font-size: 15px;font-weight: 400;color: #0E1828;margin-top: 20px;">
                      {{ homeItem5.remark[3].content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="homePage6" :style="{backgroundImage:'url('+homeImge6+')'}">
          <div class="homebox6">
            <div class="homebox6_1" :class="activeIndex==5?'animate__animated animate__fadeInDownBig':''">
              <img src="../static/homeBox6_1.png" style="width: 607px;height: 103px;"/>
            </div>
            <div style="width: 80%;margin: auto;">
              <div class="homebox6_22"
                   :class="activeIndex==5?'animate__animated animate__fadeInLeftBig':''">
                <div
                    style="width: 106px;height: 5px;background: linear-gradient(314deg, #55FDF4 0%, #005DFF 100%);">
                </div>
                <div style="margin-top: 44px;width: 50%;">
                  <div v-html="homeItem6.typeInfo"></div>
                </div>
                <div style="margin-top: 71px;display: flex;cursor: pointer;">
                  <div>READ MORE</div>
                  <img src="../static/back.png" style="width: 26px;height: 26px;"/>
                </div>
              </div>
              <div :class="activeIndex==5?'animate__animated animate__fadeInRightBig':''"
                   class="homebox6Back">
                <img src="../static/homeBox6_2.png" style="width: 839px;height: 831px;"/>
              </div>
            </div>

          </div>
        </div>
        <div class="swiper-slide" id="homePage7" :style="{backgroundImage:'url('+homeImge7+')'}">
          <div class="homebox7">
            <div class="homeBox7_1" :class="activeIndex==6?'animate__animated animate__fadeInDownBig':''">
              <img src="../static/homeBox7_1.png" style="width: 889px;height: 98px;"/>
            </div>
            <div class="homeBox7_2">
              <div class="homeBox7_21" :class="activeIndex==6?'animate__animated animate__fadeInLeftBig':''">
                <img src="../static/homeBox7_2.png" style="width: 100%;height: 100%;"/>
              </div>
              <div class="homeBox7_22" v-if="homeItem7.typePic"
                   :class="activeIndex==6?'animate__animated animate__fadeInRightBig':''">
                <div style="display: flex;">
                  <img :src="homeItem7.typePic[0]" style="width: 48px;height:48px;"/>
                  <div style="margin-left: 40px;padding-top: 12px;">{{ homeItem7.remark[0].content }}</div>
                </div>
                <div style="display: flex;margin-top: 50px;">
                  <img :src="homeItem7.typePic[1]" style="width: 48px;height:48px;"/>
                  <div style="margin-left: 40px;padding-top: 12px;">{{ homeItem7.remark[1].content }}</div>
                </div>
                <div style="display: flex;margin-top: 50px;">
                  <img :src="homeItem7.typePic[2]" style="width: 48px;height:48px;"/>
                  <div style="margin-left: 40px;padding-top: 12px;">{{ homeItem7.remark[2].content }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="studyAdhd11">
          <div class="studyAdhd11" :style="{backgroundImage:'url('+homeImge8+')'}">
            <div class="studyAdhd11box">
              <div class="studyAdhdbox" style="display: flex;justify-content: space-between">
                <div class="studyAdhd11boxleft" :class="activeIndex==10?'animate__animated animate__fadeInLeftBig':''">
                  <div class="studyAdhd11boxleft1"></div>
                  <div class="studyAdhd11boxleft2">
                    <div>中国（湖南）自由贸易试验区长沙片区长沙经开区区块人民东路二段166号海凭医疗器械产业园3#栋1206、1207室</div>
                    <div>13337816281</div>
                    <div>wuxia@bqbrain.com</div>
                  </div>
                </div>
                <div class="studyAdhd11boxright"
                     :class="activeIndex==10?'animate__animated animate__fadeInRightBig':''">
                  <div class="studyAdhd11_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">了解ADHD</div>
                    <div>什么是注意力缺陷多动障碍</div>
                    <div>ADHD的数据和统计</div>
                    <div>诊断注意力缺陷多动障碍</div>
                    <div>注意力缺陷多动障碍的治疗</div>
                    <div>补充治疗</div>
                    <div>注意力缺陷多动障碍的共患病</div>
                    <div>常见问题</div>
                    <div>ADHD的误解</div>
                    <div>前沿</div>
                  </div>
                  <div class="studyAdhd11_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">产品&解决方案</div>
                    <div>一键式海筛工具</div>
                    <div>医教家全面测评量表</div>
                    <div>精准用药</div>
                    <div>多维度神经心理测验</div>
                    <div>注意力测评及训练</div>
                    <div>家/校目标行为干预数字化工具</div>
                    <div>儿童专属正念</div>
                    <div>真实世界研究工具</div>
                    <div>家长培训课程</div>
                    <div>居家感与训练</div>
                    <div>IEP项目</div>
                  </div>
                  <div class="studyAdhd11_boxbox" style="width: 230px;">
                    <div style="font-size: 36px;font-weight: bold;">宣传&教育</div>
                    <div>ADHDNews</div>
                    <div>ADHD月刊</div>
                    <div>培训与活动</div>
                    <div>客户故事</div>
                    <div>播客</div>
                    <div style="font-size: 36px;margin-top: 50px;font-weight: bold;">加入皕启</div>
                    <div>皕启脑科学</div>
                    <div>招聘岗位</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="studyAdhdfoother" :class="activeIndex==10?'animate__animated animate__backInUp':''">
              <div class="studyAdhdbox"
                   style="display: flex;justify-content: center;flex-wrap: wrap;color: #FFFFFF;align-items: center;height: 100%;">
                <div >
                  湖南皕启科技有限公司 © 2023 备案号:
                  <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #FFFFFF;text-decoration:none">湘ICP备2024066802号-1</a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import {websiteApiType} from '@/api/api'

export default {
  data() {
    return {
      swiperOption: {},
      activeIndex: 0,
      isPath: 0,
      isshow: 1,
      homeImge1: "",
      homeImge2: "",
      homeImge3: "",
      homeImge4: "",
      homeImge5: "",
      homeImge6: "",
      homeImge7: "",
      homeImge8: "",
      contentData: [],
      homeItem1: "",
      homeItem2jzrk: [],
      homeItem2zyrs: [],
      homeItem2jsrl: [],
      homeItem2xsrk: [],
      homeItem3: "",
      homeItem4: "",
      homeItem5: "",
      homeItem6: "",
      homeItem7: "",

    };
  },
  created() {
    this.$parent.changeTitleMenu(0)
  },
  computed: {},
  mounted() {
    this.getSwiper()
    this.getAllData()
    this.getContData()
  },
  methods: {
    getContData() {
      let that = this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 6,
      }
      websiteApiType(data).then(res => {
        that.contentData = res.data.data.records
        for (let i = 0; i < this.contentData.length; i++) {
          if (this.contentData[i].typeName == '首页') {
            this.homeItem1 = this.contentData[i]
          }
          if (this.contentData[i].typeName == '家长入口') {
            this.homeItem2jzrk = this.contentData[i]
            this.homeItem2jzrk.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == '专业人士入口') {
            this.homeItem2zyrs = this.contentData[i]
            this.homeItem2zyrs.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == '教室入口') {
            this.homeItem2jsrl = this.contentData[i]
            this.homeItem2jsrl.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == '学术入口') {
            this.homeItem2xsrk = this.contentData[i]
            this.homeItem2xsrk.typePic = this.contentData[i].typePic.split(',')
          }


          if (this.contentData[i].typeName == '品牌故事') {
            this.homeItem3 = this.contentData[i]
            this.homeItem3.remark = JSON.parse(this.contentData[i].remark)
          }
          if (this.contentData[i].typeName == '了解ADHD') {
            this.homeItem4 = this.contentData[i]
          }
          if (this.contentData[i].typeName == '客户故事') {
            this.homeItem5 = this.contentData[i]
            this.homeItem5.remark = JSON.parse(this.contentData[i].remark).map(item => {
              let timeData = item.name.split('-')
              return {
                ...item,
                title: timeData[1],
                year: timeData[0].split('/')[0],
                month: timeData[0].split('/')[1],
                day: timeData[0].split('/')[2],
              }
            })
            console.log(this.homeItem5, "xxxxxxxxxxx")
          }
          if (this.contentData[i].typeName == '我们的产品') {
            this.homeItem6 = this.contentData[i]
          }
          if (this.contentData[i].typeName == '联系我们') {
            this.homeItem7 = this.contentData[i]
            this.homeItem7.remark = JSON.parse(this.contentData[i].remark)
            this.homeItem7.typePic = this.contentData[i].typePic.split(',')
          }
        }
      }).catch(err => console.log(err))
    },
    getAllData() {
      let that = this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 1,
      }
      websiteApiType(data).then(res => {
        let imgData = res.data.data.records
        for (let i = 0; i < imgData.length; i++) {
          let nameType = imgData[i].typeName
          if (nameType == "模块1") {
            that.homeImge1 = imgData[i].typePic;
          }
          if (nameType == "模块2") {
            that.homeImge2 = imgData[i].typePic;
          }
          if (nameType == "模块3") {
            that.homeImge3 = imgData[i].typePic;
          }
          if (nameType == "模块4") {
            that.homeImge4 = imgData[i].typePic;
          }
          if (nameType == "模块5") {
            that.homeImge5 = imgData[i].typePic;
          }
          if (nameType == "模块6") {
            that.homeImge6 = imgData[i].typePic;
          }
          if (nameType == "模块7") {
            that.homeImge7 = imgData[i].typePic;
          }
          if (nameType == "模块8") {
            that.homeImge8 = imgData[i].typePic;
          }
        }
      }).catch(err => console.log(err))
    },
    changezhan() {
      this.isshow = 1
    },
    changewi() {
      this.swiperOption.slideTo(0);
    },
    getSwiper() {
      let that = this
      this.swiperOption = new Swiper(".swiper-container", {
        direction: 'vertical',
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          touchStart: function (e) {
            // 打印当前索引
            that.activeIndex = that.swiperOption.activeIndex
            that.isshow = 0
            that.$parent.changeTitleMenu(that.activeIndex)
          },
          slideChange: function (e) {
            that.activeIndex = that.swiperOption.activeIndex
            that.isshow = 0
            that.$parent.changeTitleMenu(that.activeIndex)
          },
        },
      })
    }
  }
};
</script>

<style scoped>
.homebox2Back1 {
  background-image: url("../static/homebox2Back1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 11.25vw;
  height: 15.74vh;
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: none;
}

.homebox2Back2 {
  background-image: url("../static/homebox2Back2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 12.65vw;
  height: 14.72vh;
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: none;
}

.homebox2Back3 {
  background-image: url("../static/homebox2Back3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 9.73vw;
  height: 16.8vh;
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: none;
}

.homebox2Back4 {
  background-image: url("../static/homebox2Back4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 11.92vw;
  height: 16.38vh;
  position: absolute;
  right: 10px;
  bottom: -15vh;
  display: none;
}

.ceshititle {
  width: 60px;
  height: 80px;
  position: absolute;
  z-index: 99;
  background: aliceblue;
  right: 8vw;
}

.home {
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
}

.HomeTitleTextClick {
  cursor: pointer;
  height: 40px;
}

.HomeTitleTextClick:hover {
  font-size: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: rgba(0, 93, 255);
  border-bottom: 3px solid rgba(0, 93, 255);
}

.HomeTitleText {
  font-size: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: rgba(0, 93, 255, 0.5);
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 800px;
}

.HomeTitleText2 {
  font-size: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: rgba(0, 93, 255);
  border-bottom: 3px solid rgba(0, 93, 255);
}

.HomeTitle {
  width: 100vw;
  height: 90px;
  position: absolute;
  top: 0;
  z-index: 999;
}

.HomeTitleBox {
  width: 80%;
  margin: auto;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.homebox {
  width: 100%;
  height: 100%;
  position: relative;
}

.homebox2 {
  display: flex;
  justify-content: space-between;
  width: 73vw;
  margin: auto;
  margin-top: 30vh;

}

.homebox3 {
  width: 73vw;
  margin: auto;
  margin-top: 14.6vh;
  color: #005DFF;
}

.homebox2_box_Text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
}

.homebox2_box {
  width: 17vw;
  font-size: 36px;
  height: 38.6vh;
  background: rgba(0, 49, 153, 0.45);
  border-radius: 8px 5vw 8px 8px;
  backdrop-filter: blur(10px);
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: #FFFFFF;
  overflow: hidden;
}

.homebox2_box:hover {
  font-size: 40px;
  color: #FFFFFF;
  overflow: hidden;
  background-color: rgba(0, 49, 153, 0.25);
  position: relative;
}

.homebox2_box:hover .homebox2_box_Text {
  margin-top: -40px;
}

.homebox2_box:hover img {
  margin-top: -40px;
}

.homebox2_box:hover .homebox2Backs {
  display: inline-block !important;
  animation: martop 1s ease 1;
}

@keyframes martop {
  0% {
    bottom: -15vh;
  }

  100% {
    bottom: 2vh;
  }
}

.homebox2_box_img {
  margin: auto;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
}

.homebox2_box_img img {
  width: 100px;
  height: 137px;
}

#homePage1 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#homePage2 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#homePage3 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#homePage4 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#homePage5 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#homePage6 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#homePage7 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#homePage8 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  right: 0 !important;
  left: 3% !important;
  width: 50px !important;
}

.swiper-pagination >>> .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  border: 0;
  margin: 10px 0 0 10px;
  border-radius: 50%;
  background: #005dff;
}

.swiper-pagination >>> .swiper-pagination-bullet-active {
  margin: 10px 0 0 7px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: linear-gradient(180deg, #55FDF4 0%, #005DFF 100%);
}

.box1_1 {
  width: 40vw;
  height: 36.7vh;
  position: absolute;
  left: 11vw;
  top: 30vh;
}

.box1_2 {
  width: 4vw;
  height: 4vw;
  position: absolute;
  left: 50%;
  bottom: 154px;
}

.homebox3_2 {
  font-size: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  width: 728px;
  height: 252px;
  margin-top: 54px;
}

.homebox3_3 {
  display: flex;
  justify-content: space-between;
  width: 1000px;
}

.homebox3_31 {
  width: 260px;
  text-align: center;
}

.homebox3_311 {
  display: flex;
  justify-content: center;
}

.homebox3_312 {
  font-size: 24px;
  line-height: 1.5;
  margin-top: 48px;
}

.homebox3Imge {
  position: absolute;
  width: 23.1vw;
  height: 55vh;
  right: 6vw;
  bottom: 28vh;
}

.homebox4 {
  width: 73vw;
  margin: auto;
  margin-top: 14.6vh;
  color: #005DFF;
  font-size: 20px;
}

.homebox4_1 {
}

.homebox4_2 {
  display: flex;
  justify-content: space-between;
  margin-top: 142px;
}

.homebox4_22 {
  width: 834px;
  height: 539px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding: 110px 49px 49px 49px;
  line-height: 1.5;
}

.homebox5 {
  width: 73vw;
  margin: auto;
  margin-top: 14.6vh;
}

.homebox5_2 {
  display: flex;
  flex-wrap: wrap;
  width: 1036px;
  justify-content: space-between;
  float: right;
  margin-top: 68px
}

.homebox5_2Box {
  width: 493px;
  height: 287px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  padding: 57px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.homebox6 {
  width: 73vw;
  margin: auto;
  margin-top: 14.6vh;
}

.homebox6_1 {
  width: 100%;
  text-align: center;
}

.homebox6_22 {
  width: 50%;
  height: 517px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding: 110px 49px 49px 49px;
  line-height: 1.5;
  position: absolute;
  bottom: 105px;
}

.homebox6Back {
  position: absolute;
  bottom: 0px;
  right: 121px;
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}

.homebox7 {
  width: 73vw;
  margin: auto;
  margin-top: 14.6vh;
}

.homeBox7_1 {
  width: 100%;
  text-align: right;
}

.homeBox7_2 {
  display: flex;
  justify-content: space-between;
  margin-top: 115px;
}

.homeBox7_21 {
  width: 392px;
  height: 523px;
}

.homeBox7_22 {
  width: 834px;
  height: 481px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  margin-top: 42px;
  box-sizing: border-box;
  padding: 99px 83px 0px 83px;
}

.homebox8 {
  width: 73vw;
  margin: auto;
  margin-top: 14.6vh;
  position: relative;
  height: 100%;
}

.homebox8_1 {
  width: 263px;
  position: absolute;
  top: 30vh;
  left: 0;
}

.homebox8_11 {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  margin-top: 33px;
  line-height: 1.5;
}

.homebox8_2 {
  color: #000000;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
}

.homebox8Bottom {
  width: 100vw;
  height: 120px;
  background: #363636;
  color: #fff;
  position: absolute;
  bottom: 0;
  font-size: 32px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.homebox8_2box {
  width: 340px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  line-height: 3;
}

.studyAdhd11box {
  background-image: url("@/static/studyAdhd/studyAdhd11.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 70vh;
}

.studyAdhdfoother {
  width: 100%;
  height: 10vh;
  background: #363636;
}

.studyAdhd11boxleft1 {
  background-image: url("@/static/studyAdhd/studyAdhd11boxleft1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.69vw;
  height: 9.7vh;
}

#studyAdhd11 {
  width: 100%;
  position: relative;
}

.studyAdhd11boxleft2 {
  font-size: 0.6vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  margin-top: 3vh;
}

.studyAdhd11 {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.studyAdhd11boxright {
  display: flex;
  justify-content: space-between;
  width: 44vw;
  line-height: 3;
}

.studyAdhdbox {
  width: 75vw;
  margin: auto;
  position: relative;
}
</style>
<style>
.box1_2Imge {
  animation: ripple 0.7s linear infinite;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 93, 255, 0.1), 0 0 0 1em rgba(0, 93, 255, 0.1), 0 0 0 3em rgba(0, 93, 255, 0.1), 0 0 0 5em rgba(0, 93, 255, 0.1);
    border-radius: 100%;
  }

  100% {
    box-shadow: 0 0 0 1em rgba(0, 93, 255, 0.1), 0 0 0 3em rgba(0, 93, 255, 0.1), 0 0 0 5em rgba(0, 93, 255, 0.1), 0 0 0 8em rgba(0, 93, 255, 0);
    border-radius: 100%;
  }
}
</style>
