<template>
    <div class="studyAdhd">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge1+')'}" id="studyAdhd1">
            <div class="studyAdhd1_1">
              <div class="studyAdhd1_1box" :class="activeIndex==0?'animate__animated animate__bounceInDown':'animate__animated animate__backOutUp'">
                了解ADHD
              </div>
            </div>
            <div class="studyAdhd1_2" :class="activeIndex==0?'animate__animated animate__bounceInRight':'animate__animated animate__backOutRight'">
              <div class="studyAdhd1_2box">
                <div class="studyAdhd1_2boxText">
                  <div v-for="item in homeItem1.remark">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge2+')'}" id="studyAdhd2">
            <div class="studyAdhd2_1">
              <div class="studyAdhdbox">
                <div class="studyAdhd2_1title">
                 <div class="studyAdhd2_1Img" :class="activeIndex==1?'animate__animated animate__slideInDown':'animate__animated  animate__slideInUp'">
                   ADHD
                 </div>
                </div>
                <div class="studyAdhd2_1Text">
                  <div v-html="homeItem2.typeInfo"></div>
                </div>
              </div>
            </div>
            <div class="studyAdhd2_2">
              <div class="studyAdhdbox studyAdhd2_2Box" v-if="homeItem2.remark">
                <div class="studyAdhd2_2left" :class="activeIndex==1?'animate__animated animate__fadeInLeftBig':'animate__animated animate__fadeInRightBig'"></div>
                <div class="studyAdhd2_2right" :class="activeIndex==1?'animate__animated animate__fadeInRightBig':'animate__animated animate__fadeInLeftBig'">
                  <div class="studyAdhd2_2right1">{{homeItem2.remark[0].name}}</div>
                  <div class="studyAdhd2_2right2">
                    <div v-html="homeItem2.remark[0].content"></div>
                    <div class="studyAdhd2_2right3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge3+')'}" id="studyAdhd3">
            <div class="studyAdhdbox">
              <div class="studyAdhd3Title" >
                <div>ADHD的数据和统计</div>
                <div class="studyAdhdborder"></div>
              </div>
              <div class="studyAdhd3item" v-if="homeItem3.remark">
                <div class="studyAdhd3box" :class="activeIndex==2?'animate__animated animate__zoomIn':''">
                  <div class="studyAdhd3box1_1" :style="{backgroundImage:'url('+homeItem3.typePic[0]+')'}">
                  </div>
                  <div class="studyAdhd3box1_2">{{ homeItem3.remark[0].name }}</div>
                  <div class="studyAdhd3box1_3">{{ homeItem3.remark[0].content }}</div>
                </div>
                <div :class="activeIndex==2?'animate__animated animate__zoomIn':''" class="studyAdhd3box">
                  <div class="studyAdhd3box1_1" :style="{backgroundImage:'url('+homeItem3.typePic[1]+')'}">
                  </div>
                  <div class="studyAdhd3box1_2">{{ homeItem3.remark[1].name }}</div>
                  <div class="studyAdhd3box1_3">{{ homeItem3.remark[1].content }}</div>
                </div>
                <div :class="activeIndex==2?'animate__animated animate__zoomIn':''" class="studyAdhd3box">
                  <div class="studyAdhd3box1_1" :style="{backgroundImage:'url('+homeItem3.typePic[2]+')'}">
                  </div>
                  <div class="studyAdhd3box1_2">{{ homeItem3.remark[2].name }}</div>
                  <div class="studyAdhd3box1_3">{{ homeItem3.remark[2].content }}</div>
                </div>
              </div>
              <div class="studyAdhd3fotter">
                <div class="studyAdhd2_2right3Img">
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge4+')'}" id="studyAdhd4">
            <div class="studyAdhdbox">
              <div class="studyAdhd3Title">
                <div style="color: #FFFFFF">ADHD的科学</div>
                <div class="studyAdhdborder"></div>
              </div>
              <div class="studyAdhd4item">
                <div class="studyAdhd4itemleft" :class="activeIndex==3?'animate__animated animate__fadeInLeftBig':''">
                  <div class="studyAdhd2_2right3Img"></div>
<!--                  <div class="studyAdhd4itemleft1"></div>-->
                </div>
                <div class="studyAdhd4itemrigth"  v-if="homeItem4.remark">
                  <div :class="activeIndex==3?'animate__animated animate__flipInX':''" class="studyAdhd4itemrigthbox">
                    <div class="studyAdhd4box1_1" :style="{backgroundImage:'url('+homeItem4.typePic[0]+')'}">
                    </div>
                    <div class="studyAdhd4box1_2">{{ homeItem4.remark[0].name }}</div>
                    <div class="studyAdhd4box1_3">{{ homeItem4.remark[0].content }}</div>
                  </div>
                  <div :class="activeIndex==3?'animate__animated animate__flipInX':''" class="studyAdhd4itemrigthbox">
                    <div class="studyAdhd4box1_1" :style="{backgroundImage:'url('+homeItem4.typePic[0]+')'}">
                    </div>
                    <div class="studyAdhd4box1_2">{{ homeItem4.remark[0].name}}</div>
                    <div class="studyAdhd4box1_3">{{ homeItem4.remark[0].content}}</div>
                  </div>
                  <div :class="activeIndex==3?'animate__animated animate__flipInX':''" class="studyAdhd4itemrigthbox">
                    <div class="studyAdhd4box1_1" :style="{backgroundImage:'url('+homeItem4.typePic[0]+')'}">
                    </div>
                    <div class="studyAdhd4box1_2">{{ homeItem4.remark[0].name}}</div>
                    <div class="studyAdhd4box1_3">{{ homeItem4.remark[0].content }}</div>
                  </div>
                  <div :class="activeIndex==3?'animate__animated animate__flipInX':''" class="studyAdhd4itemrigthbox">
                    <div class="studyAdhd4box1_1" :style="{backgroundImage:'url('+homeItem4.typePic[0]+')'}">
                    </div>
                    <div class="studyAdhd4box1_2">{{ homeItem4.remark[0].name}}</div>
                    <div class="studyAdhd4box1_3">{{ homeItem4.remark[0].content}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide" id="studyAdhd5">
            <div class="studyAdhd5Back"></div>
            <div class="studyAdhdbox studyAdhd5item">
              <div :class="activeIndex==4?'animate__animated animate__fadeInLeftBig':''" class="studyAdhd5itemleft" >
                <div class="studyAdhd3Title">
                  <div>ADHD的科学</div>
                  <div class="studyAdhdborder" ></div>
                </div>
                <div class="studyAdhd5itemlefttext">
                  <div v-html="homeItem5.typeInfo"></div>
                </div>
                <div class="studyAdhd2_2right3Img" ></div>
              </div>
              <div :class="activeIndex==4?'animate__animated animate__fadeInRightBig':''" class="studyAdhd5itemright" :style="{backgroundImage:'url('+homeItem5.typePic+')'}"></div>
            </div>
          </div>
          <div class="swiper-slide" id="studyAdhd6">
              <div class="studyAdhd6Back"></div>
            <div class="studyAdhdbox" style="text-align: right">
              <div class="studyAdhd3Title" >
                <div>注意力缺陷多动障碍的治疗</div>
                <div class="studyAdhdborder" style="position: absolute;right: 15vw;top: 1vh;"></div>
              </div>
            </div>
            <div class="studyAdhdbox studyAdhd6item" v-if="homeItem6.remark">

              <div class="studyAdhd6box" :class="activeIndex==5?'animate__animated animate__flipInX':''" >
                  <div class="studyAdhd6box1_0">
                    <div class="studyAdhd6box1_1">{{ homeItem6.remark[0].name }}</div>
                    <div class="studyAdhd6box1_2" :style="{backgroundImage:'url('+homeItem6.typePic[0]+')'}" ></div>
                  </div>
                  <div class="studyAdhd6box1_3">
                    {{ homeItem6.remark[0].content }}
                  </div>
              </div>
              <div class="studyAdhd6box" :class="activeIndex==5?'animate__animated animate__flipInX':''" >
                <div class="studyAdhd6box1_0">
                  <div class="studyAdhd6box1_1">{{ homeItem6.remark[1].name }}</div>
                  <div class="studyAdhd6box1_2" :style="{backgroundImage:'url('+homeItem6.typePic[1]+')'}" ></div>
                </div>
                <div class="studyAdhd6box1_3">
                  {{ homeItem6.remark[1].content }}
                </div>
              </div>
              <div class="studyAdhd6box1">
                <div class="studyAdhd2_2right3Img"></div>
              </div>
              <div class="studyAdhd6box" :class="activeIndex==5?'animate__animated animate__flipInX':''" >
                <div class="studyAdhd6box1_0">
                  <div class="studyAdhd6box1_1">{{ homeItem6.remark[2].name }}</div>
                  <div class="studyAdhd6box1_2" :style="{backgroundImage:'url('+homeItem6.typePic[2]+')'}" ></div>
                </div>
                <div class="studyAdhd6box1_3">
                  {{ homeItem6.remark[2].content }}
                </div>
              </div>
              <div class="studyAdhd6box" :class="activeIndex==5?'animate__animated animate__flipInX':''" >
                <div class="studyAdhd6box1_0">
                  <div class="studyAdhd6box1_1">{{ homeItem6.remark[3].name }}</div>
                  <div class="studyAdhd6box1_2" :style="{backgroundImage:'url('+homeItem6.typePic[3]+')'}" ></div>
                </div>
                <div class="studyAdhd6box1_3">
                  {{ homeItem6.remark[3].content }}
                </div>
              </div>
              <div class="studyAdhd6box" :class="activeIndex==5?'animate__animated animate__flipInX':''" >
                <div class="studyAdhd6box1_0">
                  <div class="studyAdhd6box1_1">{{ homeItem6.remark[4].name }}</div>
                  <div class="studyAdhd6box1_2" :style="{backgroundImage:'url('+homeItem6.typePic[4]+')'}" ></div>
                </div>
                <div class="studyAdhd6box1_3">
                  {{ homeItem6.remark[4].content }}
                </div>
                </div>
            </div>
          </div>
           <div class="swiper-slide" :style="{backgroundImage:'url('+homeImge7+')'}" id="studyAdhd7">
             <div class="studyAdhdbox studyAdhd7item">
               <div class="studyAdhd7itemleft" :class="activeIndex==6?'animate__animated animate__fadeInLeftBig':''" :style="{backgroundImage:'url('+homeItem7.typePic+')'}"></div>
               <div class="studyAdhd7itemright" :class="activeIndex==6?'animate__animated animate__fadeInRightBig':''">
                 <div class="studyAdhd3Title" style="color: #FFFFFF">
                   <div>补充治疗</div>
                   <div class="studyAdhdborder"  ></div>
                 </div>
                  <div class="studyAdhd7itemrightText">
                    {{homeItem7.remark}}
                  </div>
                 <div class="studyAdhd2_2right3Img" style="margin-top: 3vh"></div>
               </div>
             </div>
          </div>
           <div class="swiper-slide" id="studyAdhd8">
             <div class="studyAdhd8Back"></div>
             <div class="studyAdhdbox studyAdhd8item">
                <div class="studyAdhd8itemleft" :class="activeIndex==7?'animate__animated animate__fadeInLeftBig':''">
                  <div class="studyAdhd8Title">
                    <div>ADHD的科学</div>
                    <div class="studyAdhdborder"></div>
                  </div>
                  <div class="studyAdhd8itemleftText">
                   {{homeItem8.remark}}
                  </div>
                </div>
                <div class="studyAdhd8itemright" :class="activeIndex==7?'animate__animated animate__fadeInRightBig':''" :style="{backgroundImage:'url('+homeItem8.typePic+')'}"></div>
             </div>
          </div>
           <div class="swiper-slide" id="studyAdhd9">
             <div class="studyAdhd9Back1"></div>
             <div class="studyAdhd9Back2"></div>
             <div class="studyAdhdbox" v-if="homeItem9.remark">
               <div class="studyAdhd9Title">
                 <div class="studyAdhd9Title1">常见问题</div>
                 <div class="studyAdhd9Title2">关于注意力缺陷多动障碍的一般问题</div>
               </div>
                <div class="studyAdhd9item1"></div>
                <div class="studyAdhd9item2"></div>
               <div style="height: 60vh;overflow: auto;padding: 10px;box-sizing: border-box">
                 <div class="studyAdhd9item3" :class="activeIndex==8?'animate__animated animate__zoomInDown':''" v-for="(item,index) in homeItem9.remark" :key="index" @click="changeProblemData(index)">
                   <div style="display: flex;justify-content: space-between">
                     <div style="color: black">Q：{{ item.name }}</div>
                     <img src="@/static/studyAdhd/zhankai.png" style="width: 16px;height: 9px" v-if="!item.isshow">
                     <img src="@/static/studyAdhd/shouqi.png" style="width: 16px;height: 9px" v-if="item.isshow">
                   </div>
                   <div style="color:#5A7184;bottom: 20px;margin-top: 20px" v-if="item.isshow">{{ item.content }}</div>
                 </div>
               </div>
             </div>
          </div>
           <div class="swiper-slide" id="studyAdhd10">
             <div class="studyAdhdbox studyAdhd10item" v-if="homeItem10.remark">
                <div class="studyAdhd10itemleft"  :style="{backgroundImage:'url('+homeItem10.typePic+')'}"  :class="activeIndex==9?'animate__animated animate__fadeInLeftBig':''"></div>
                <div  class="studyAdhd10itemright" :class="activeIndex==9?'animate__animated animate__fadeInRightBig':''" >
                  <div class="studyAdhd10itemright1">ADHD的误解</div>
                  <div class="studyAdhd10itemrightbox" v-for="(item,index) in homeItem10.remark">
                    <div :class="'studyAdhd10itemrightbox1'+(index+1)"></div>
                    <div class="studyAdhd10itemrightbox2">
                      <div class="studyAdhd10itemrightbox2Text1">{{ item.name }}</div>
                      <div class="studyAdhd10itemrightbox2Text2">{{ item.content }}</div>
                    </div>
                  </div>
                </div>
             </div>
          </div>
           <div class="swiper-slide"  id="studyAdhd11">
             <div class="studyAdhd11">
               <div class="studyAdhd11box">
                 <div class="studyAdhdbox" style="display: flex;justify-content: space-between">
                   <div class="studyAdhd11boxleft" :class="activeIndex==10?'animate__animated animate__fadeInLeftBig':''">
                     <div class="studyAdhd11boxleft1"></div>
                     <div class="studyAdhd11boxleft2">
                       <div>中国（湖南）自由贸易试验区长沙片区长沙经开区区块人民东路二段166号海凭医疗器械产业园3#栋1206、1207室</div>
                       <div>13337816281</div>
                       <div>wuxia@bqbrain.com</div>
                     </div>
                   </div>
                   <div class="studyAdhd11boxright" :class="activeIndex==10?'animate__animated animate__fadeInRightBig':''">
                     <div class="studyAdhd11_boxbox">
                       <div style="font-size: 36px;font-weight: bold;">了解ADHD</div>
                       <div>什么是注意力缺陷多动障碍</div>
                       <div>ADHD的数据和统计</div>
                       <div>诊断注意力缺陷多动障碍</div>
                       <div>注意力缺陷多动障碍的治疗</div>
                       <div>补充治疗</div>
                       <div>注意力缺陷多动障碍的共患病</div>
                       <div>常见问题</div>
                       <div>ADHD的误解</div>
                       <div>前沿</div>
                     </div>
                     <div class="studyAdhd11_boxbox">
                       <div style="font-size: 36px;font-weight: bold;">产品&解决方案</div>
                       <div>一键式海筛工具</div>
                       <div>医教家全面测评量表</div>
                       <div>精准用药</div>
                       <div>多维度神经心理测验</div>
                       <div>注意力测评及训练</div>
                       <div>家/校目标行为干预数字化工具</div>
                       <div>儿童专属正念</div>
                       <div>真实世界研究工具</div>
                       <div>家长培训课程</div>
                       <div>居家感与训练</div>
                       <div>IEP项目</div>
                     </div>
                     <div class="studyAdhd11_boxbox" style="width: 230px;">
                       <div style="font-size: 36px;font-weight: bold;">宣传&教育</div>
                       <div>ADHDNews</div>
                       <div>ADHD月刊</div>
                       <div>培训与活动</div>
                       <div>客户故事</div>
                       <div>播客</div>
                       <div style="font-size: 36px;margin-top: 50px;font-weight: bold;">加入皕启</div>
                       <div>皕启脑科学</div>
                       <div>招聘岗位</div>
                     </div>
                   </div>
                 </div>
               </div>
               <div class="studyAdhdfoother" :class="activeIndex==10?'animate__animated animate__backInUp':''">
                 <div class="studyAdhdbox"
                      style="display: flex;justify-content: center;flex-wrap: wrap;color: #FFFFFF;align-items: center;height: 100%;">
                   <div >
                     湖南皕启科技有限公司 © 2023 备案号:
                     <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #FFFFFF;text-decoration:none">湘ICP备2024066802号-1</a>
                   </div>
                 </div>
               </div>
             </div>

          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
</template>
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import {websiteApiType} from "@/api/api";
export default {
  data() {
    return {
      swiperOption: {},
      activeIndex: 0,
      problemData:[
        {
          isshow:true,
          name:"什么是注意力缺陷多动障碍？",
          item:"注意力缺陷/多动障碍（ADHD）是一种慢性神经发育障碍，影响11%的学龄儿童。在超过四分之三的病例中，症状持续到成年期。多动症的特征是发育不适当的注意力不集中、冲动和多动症。"
        },{
          isshow:false,
          name:"什么是执行功能？",
          item:"注意力缺陷/多动障碍（ADHD）是一种慢性神经发育障碍，影响11%的学龄儿童。在超过四分之三的病例中，症状持续到成年期。多动症的特征是发育不适当的注意力不集中、冲动和多动症。"
        },{
          isshow:false,
          name:"如何诊断注意力缺陷多动障碍？",
          item:"注意力缺陷/多动障碍（ADHD）是一种慢性神经发育障碍，影响11%的学龄儿童。在超过四分之三的病例中，症状持续到成年期。多动症的特征是发育不适当的注意力不集中、冲动和多动症。"
        },
      ],
      homeImge1:"",
      homeImge2:"",
      homeImge3:"",
      homeImge4:"",
      homeImge7:"",
      contentData:[],
      homeItem1:{},
      homeItem2:{},
      homeItem3:{},
      homeItem4:{},
      homeItem5:{},
      homeItem6:{},
      homeItem7:{},
      homeItem8:{},
      homeItem9:{},
      homeItem10:{},
    };
  },
  created() {
    this.$parent.changeTitleMenu(0)
  },
  computed: {},
  mounted() {
    this.getSwiper()
    this.getAllData()
    this.getContData()
  },
  methods: {
    getContData(){
      let that = this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 7,
      }
      websiteApiType(data).then(res => {
        that.contentData = res.data.data.records
        console.log(that.contentData,"所有数据")
        for (let i = 0; i < this.contentData.length; i++) {
          if (this.contentData[i].typeName == '了解ADHD') {
            this.homeItem1 =this.contentData[i]
            this.homeItem1.remark = JSON.parse(this.contentData[i].remark)
          }
          if (this.contentData[i].typeName == 'ADHD') {
            this.homeItem2 =this.contentData[i]
            this.homeItem2.remark = JSON.parse(this.contentData[i].remark)
          }
           if (this.contentData[i].typeName == 'ADHD的数据和统计') {
            this.homeItem3 =this.contentData[i]
            this.homeItem3.remark = JSON.parse(this.contentData[i].remark)
             this.homeItem3.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == 'ADHD的科学1') {
            this.homeItem4 =this.contentData[i]
            this.homeItem4.remark = JSON.parse(this.contentData[i].remark)
            this.homeItem4.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == 'ADHD的科学2') {
            this.homeItem5 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '注意力缺陷多动障碍的治疗') {
            this.homeItem6 =this.contentData[i]
            this.homeItem6.remark = JSON.parse(this.contentData[i].remark)
            this.homeItem6.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == '补充治疗') {
            this.homeItem7 =this.contentData[i]
          }
          if (this.contentData[i].typeName == 'ADHD的科学') {
            this.homeItem8 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '常见问题') {
            this.homeItem9 =this.contentData[i]
             let parmesData = JSON.parse(this.contentData[i].remark)
            this.homeItem9.remark = parmesData.map(item=>{
              return {
                ...item,
                'item':item.content,
                'isshow':false,
              }
            })
          }
          if (this.contentData[i].typeName == 'ADHD的误解') {
            this.homeItem10 =this.contentData[i]
            this.homeItem10.remark = JSON.parse(this.contentData[i].remark)
            console.log( this.homeItem10.remark,"xxxxxxxxxx")
          }
        }
      }).catch(err => console.log(err))
    },
    getAllData() {
      let that =this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 2,
      }
      websiteApiType(data).then(res => {
        let imgData = res.data.data.records
        for (let i = 0; i < imgData.length; i++) {
          let nameType = imgData[i].typeName
          if (nameType == "模块1") {
            that.homeImge1 = imgData[i].typePic;
          }
          if (nameType == "模块2") {
            that.homeImge2 = imgData[i].typePic;
          }
          if (nameType == "模块3") {
            that.homeImge3 = imgData[i].typePic;
          }
          if (nameType == "模块4") {
            that.homeImge4 = imgData[i].typePic;
          }
          if (nameType == "模块7") {
            that.homeImge7 = imgData[i].typePic;
          }
        }
      }).catch(err => console.log(err))
    },
    changeProblemData(index){
      this.homeItem9.remark[index].isshow = !this.homeItem9.remark[index].isshow
    },
    changewi(){
      this.swiperOption.slideTo(0);
    },
    getSwiper() {
      let that = this
      this.swiperOption = new Swiper(".swiper-container", {
        direction: 'vertical',
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          touchStart: function(e) {
            // 打印当前索引
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
          slideChange: function(e) {
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
        },
      })
    }
  }
};
</script>

<style scoped>
.studyAdhdbox{
  width: 75vw;
  margin: auto;
  position: relative;
}
.studyAdhd3Title{
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
  margin-top: 16.85vh;
}
.studyAdhd8Title{
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}
.studyAdhd3item{
  display: flex;
  justify-content: space-between;
  margin-top: 13.7vh;
}
.studyAdhd3box{
  width: 23.3vw;
  height: 38.6vh;
  background: black;
  background: rgba(0,100,182,0.2);
  border-radius: 0.5vw 6vw 0.5vw  0.5vw;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding: 2.9vh 2.7vw 2.9vh;
}
.studyAdhd3box1_1{
  background-image: url("@/static/studyAdhd/studyAdhd3box1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.4vw;
  height: 3.4vw;
}
.studyAdhd3box2_1{
  background-image: url("@/static/studyAdhd/studyAdhd3box2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.4vw;
  height: 3.4vw;
}
.studyAdhd3box3_1{
  background-image: url("@/static/studyAdhd/studyAdhd3box3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3.4vw;
  height: 3.4vw;
}
.studyAdhd3box1_2{
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: bold;
  color: #15233B;
  margin-top: 3vh;
}
.studyAdhd3box1_3{
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #15233B;
  margin-top: 1.5vh;
  line-height: 2;
}
  .studyAdhd{
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    overflow: hidden;
  }
  #studyAdhd1 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  #studyAdhd3 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  #studyAdhd4 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  #studyAdhd7 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination {
    right: 0 !important;
    left: 3% !important;
    width: 50px !important;
  }

  .swiper-pagination>>>.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    border: 0;
    margin: 10px 0 0 10px;
    border-radius: 50%;
    background: #005dff;
  }

  .swiper-pagination>>>.swiper-pagination-bullet-active {
    margin: 10px 0 0 7px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: linear-gradient(180deg, #55FDF4 0%, #005DFF 100%);
  }
  .studyAdhd1_2{
    width: 100%;
    position: absolute;
    bottom: 13.3vh;
  }
  .studyAdhd1_2box{
    background-image: url("@/static/studyAdhd/studyAdhd1_2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 75vw;
    height: 13.89vh;
    margin: auto;
    position: relative;
  }
  .studyAdhd1_3{
    background-image: url("@/static/studyAdhd/studyAdhd1_3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 15.83vw;
    height: 37.1vh;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .studyAdhd1_1{
    width: 100%;
    height: 30vh;
    position: absolute;
    top: 21.9vh;
    font-size: 5.5vw;
    font-family: SourceKeynoteartHans;
    font-weight: 400;
    color: #15233B;
    box-sizing: border-box;
    padding-top: 2vh;
  }
  .studyAdhd1_1box{
    width: 77.6vw;
    height: 30vh;
    margin: auto;
    position: relative;
    box-sizing: border-box;
    padding-top: 5vh;
    text-shadow: 4px 4px 60px rgba(0,84,246,0.91);
    font-weight: 400;
    color: #FFFFFF;
  }
  .studyAdhd1_1boxback{
    background-image: url("@/static/studyAdhd/studyAdhd1_1boxback.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 34.53vw;
    height: 29.35vh;
    position: absolute;
    left: 6vw;
    bottom: 0;
  }
  .studyAdhd1_2boxText{
    width: 47vw;
    height: 13vh;
    left: 9vw;
    position: absolute;
    overflow-x: auto;
    font-size: 1vw;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    box-sizing: border-box;
    padding: 1vw;
    color: #005DFF;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .studyAdhd1_2boxText div{
    margin-left: 20px;
  }
  .studyAdhd2_1{
    background-image: url("@/static/studyAdhd/studyAdhd2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width:100vw;
    height: 27.87vh;
    position: relative;
  }
  .studyAdhd2_1title{
    width: 100%;
    height: 13.88vh;
  }
  .studyAdhd2_1Img{
    background-image: url("@/static/studyAdhd/studyAdhd2_1title.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 12.91vw;
    height: 11.38vh;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 7vh;
    box-sizing: border-box;
    font-family: SourceKeynoteartHans;
    font-weight: 400;
  }
  .studyAdhd2_1Text{
    font-size: 1vw;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #15233B;
  }
  .studyAdhd2_2Box{
    display: flex;
    justify-content: space-between;
    margin-top: 6.25vw;
  }
  .studyAdhd2_2left{
    background-image: url("@/static/studyAdhd/studyAdhd2_2left.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 32.6vw;
    height: 49.81vh;
  }
  .studyAdhd2_2right{
    width: 40vw;
    height: 49.81vh;
  }
  .studyAdhd2_2right1{
    font-size: 1.8vw;
    font-family: SourceKeynoteartHans;
    font-weight: 400;
    color: #005DFF;
    margin-top: 8vh;
  }
.studyAdhd2_2right2{
  font-size: 0.7vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  margin-top: 2.5vw;
  line-height: 3;
  width: 70%;
}
.studyAdhd2_2right3{
  margin-top: 3vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}
.studyAdhd2_2right3Img{
  margin-top: 3vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}
.studyAdhdborder{
  width: 124px;
  height: 2px;
  margin-top: 5vh;
  background: linear-gradient(135deg, #005DFF 0%, #55FDF4 100%);
}
.studyAdhd3fotter{
  width: 100%;
  height: 4.1vh;
  display: flex;
  justify-content: flex-end;
  margin-top: 5vh;
}
.studyAdhd4item{
  display: flex;
  justify-content: space-between;
}
.studyAdhd4itemleft1{
  margin-top: 3vh;
  background-image: url("@/static/studyAdhd/studyAdhd4itemleft1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 18vh;
  width: 22.18vw;
  height: 33.88vh;
}
.studyAdhd4itemrigth{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 49vw;
  height: 70vh;
  margin-top: -10vh;
}
.studyAdhd4itemrigthbox{
  width: 23.3vw;
  height: 30.37vh;
  background: rgba(255,255,255,0.7);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding: 3.6vh 2.7vw 3.6vh;
}
.studyAdhd4box1_1{
  background-image: url("@/static/studyAdhd/studyAdhd4box1_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd4box2_1{
  background-image: url("@/static/studyAdhd/studyAdhd4box2_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd4box3_1{
  background-image: url("@/static/studyAdhd/studyAdhd4box3_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd4box4_1{
  background-image: url("@/static/studyAdhd/studyAdhd4box4_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd4box1_2{
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: bold;
  color: #15233B;
  margin-top: 1.5vh;
}
.studyAdhd4box1_3{
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #15233B;
  margin-top: 1vh;
  line-height: 2;
}
#studyAdhd5{
  position: relative;
  width: 100%;
  height: 100%;
}
.studyAdhd5Back{
  background-image: url("@/static/studyAdhd/studyAdhd5Back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 11.425vw;
  height: 16.7vh;
  position: absolute;
  right: 0;
  top: 48px;
}
.studyAdhd5item{
  display: flex;
  justify-content: space-between;
  margin-top: 14.8vh;
}
.studyAdhd5itemleft{
  width: 33.5vw;
}
.studyAdhd5itemright{
  background-image: url("@/static/studyAdhd/studyAdhd5itemright.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 32.39vw;
  height: 68.6vh;
}
.studyAdhd5itemlefttext{
  font-size: 0.8vw;
  margin-top: 10vh;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  line-height: 2;
  color: #15233B;
}
#studyAdhd6{
  position: relative;
  width: 100%;
  height: 100%;
}
.studyAdhd6Back{
  background-image: url("@/static/studyAdhd/studyAdhd6Back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 50.578vw;
  height: 100vh;
  position: absolute;
  right: 0;
  bottom: 0;
}
.studyAdhd8Back{
  background-image: url("@/static/studyAdhd/studyAdhd8Back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 11.06vw;
  height: 20.83vh;
  position: absolute;
  left: 0;
  top: 53px;
}
.studyAdhd6item{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -14vh;
  height: 90vh;
}
.studyAdhd6box{
  width: 21.77vw;
  height: 34.9vh;
  background: #CAEAFF;
  border-radius: 8px 5.2vw 8px 8px;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding: 5.1vh 1.9vw 5.1vh;
}
.studyAdhd6box1{
  width: 21.77vw;
  height: 34.9vh;
  box-sizing: border-box;
  padding-top: 10vh;
}
.studyAdhd6box1_1{
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #15233B;
}
.studyAdhd6box1_2{
  background-image: url("@/static/studyAdhd/studyAdhd6box1_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd6box5_2{
  background-image: url("@/static/studyAdhd/studyAdhd6box1_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd6box2_2{
  background-image: url("@/static/studyAdhd/studyAdhd6box2_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd6box3_2{
  background-image: url("@/static/studyAdhd/studyAdhd6box3_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd6box4_2{
  background-image: url("@/static/studyAdhd/studyAdhd6box4_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6vw;
  height: 2.6vw;
}
.studyAdhd6box1_0{
  display: flex;
  justify-content: space-between;
  margin-top: -1vh;
}
.studyAdhd6box1_3{
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #15233B;
  margin-top: 2vh;
  line-height: 2;
}
.studyAdhd7item{
  display: flex;
  justify-content: space-between;
  margin-top: 19.1vh;
}
.studyAdhd8item{
  display: flex;
  justify-content: space-between;
  margin-top: 27.4vh;
}
.studyAdhd7itemleft{
  background-image: url("@/static/studyAdhd/studyAdhd7itemleft.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 25.7vw;
  height: 65.46vh;
}
.studyAdhd7itemright{
  width: 32.5vw;
  height: 48.79vh;
}
.studyAdhd7itemrightText{
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 2;
  margin-top: 3vh;
}
#studyAdhd8{
  position: relative;
  width: 100%;
  height: 100%;
}
.studyAdhd8itemleft{
  width: 28.84vw;
}
.studyAdhd8itemright{
  background-image: url("@/static/studyAdhd/studyAdhd8itemright.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 34.9vw;
  height: 46.38vh;
}
.studyAdhd8itemleftText{
  margin-top: 10vh;
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  line-height: 2;
}
#studyAdhd9{
  position: relative;
  width: 100%;
  height: 100%;
}
.studyAdhd9Back1{
  background-image: url("@/static/studyAdhd/studyAdhd9Back1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 11.41vw;
  height: 31.85vh;
  position: absolute;
  right: 39px;
  top: 1vh;
}
.studyAdhd9Back2{
  background-image: url("@/static/studyAdhd/studyAdhd9Back2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35vw;
  height: 35vw;
  position: absolute;
  left: -28vw;
  top: 15vh;
  animation: rotate 15s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}
.studyAdhd9item1{
  width: 100%;
  text-align: center;
  font-size: 1.8vw;
  font-family: Source-KeynoteartHans, Source-KeynoteartHans;
  font-weight: 400;
  color: #005DFF;
  margin-top: 8vh;
}
.studyAdhd9item2{
  width: 100%;
  text-align: center;
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  margin-top: 24px;
}
.studyAdhd9item3{
  width: 100%;
  font-size: 0.8vw;
  background: #FFFFFF;
  box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.03);
  border-radius: 8px;
  border: 1px solid #E5EAF4;
  padding: 2.9vw 4.3vh 2.9vw;
  box-sizing: border-box;
  margin-top: 5vh;
}
.studyAdhd10item{
  display: flex;
  justify-content: space-between;
  margin-top: 13.8vh;
}
.studyAdhd10itemleft{
  background-image: url("@/static/studyAdhd/studyAdhd10itemleft.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 29.42vw;
  height: 65.64vh;
}
.studyAdhd10itemright{
  width: 30vw;
}
.studyAdhd10itemright1{
  font-size: 1.8vw;
  font-family: Source-KeynoteartHans, Source-KeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}
.studyAdhd10itemrightbox{
  width: 100%;
  height: 5.6vh;
  display: flex;
  justify-content: space-between;
  margin-top: 6vh;
}
.studyAdhd10itemrightbox11{
  background-image: url("@/static/studyAdhd/studyAdhd10itemrightbox1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5vw;
  height: 2.5vw;
}
.studyAdhd10itemrightbox12{
  background-image: url("@/static/studyAdhd/studyAdhd10itemrightbox2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5vw;
  height: 2.5vw;
}
.studyAdhd10itemrightbox13{
  background-image: url("@/static/studyAdhd/studyAdhd10itemrightbox3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5vw;
  height: 2.5vw;
}
.studyAdhd10itemrightbox14{
  background-image: url("@/static/studyAdhd/studyAdhd10itemrightbox4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5vw;
  height: 2.5vw;
}
.studyAdhd10itemrightbox2{
  width: 26.53vw;
  height: 100%;
}
.studyAdhd10itemrightbox2Text1{
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #0E1828;
}
.studyAdhd10itemrightbox2Text2{
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #5A7184;
}
.studyAdhd11box{
  background-image: url("@/static/studyAdhd/studyAdhd11.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 70vh;
}
.studyAdhdfoother{
  width: 100%;
  height: 10vh;
  background: #363636;
}
.studyAdhd11boxleft1{
  background-image: url("@/static/studyAdhd/studyAdhd11boxleft1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.69vw;
  height:9.7vh;
}
#studyAdhd11{
  width: 100%;
  position: relative;
}
.studyAdhd11boxleft2{
  font-size: 0.6vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  margin-top: 3vh;
}
.studyAdhd11{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.studyAdhd11boxright{
  display: flex;
  justify-content: space-between;
  width: 44vw;
  line-height: 3;
}
.studyAdhd9Title{
  width: 100%;
  text-align: center;
  margin-top: 10vh;
}
.studyAdhd9Title1{
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}
.studyAdhd9Title2{
  font-size: 0.9vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  margin-top: 24px;
}
</style>
