import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// import "swiper/swiper-bundle.css";
import jquery from 'jquery';
Vue.prototype.jquery = $
import 'animate.css';
new Vue({
  router,
  store,
  // $,
  // jquery,
  render: function (h) { return h(App) }
}).$mount('#app')
