<template>
  <div class="publicize">
    <div class="swiper-container">
      <div class="swiper-wrapper" >
        <div class="swiper-slide"  :style="{backgroundImage:'url('+homeImge1+')'}" id="publicize1">
          <div class="publicize1_1" :style="{backgroundImage:'url('+homeItem1.typePic+')'}" :class="activeIndex==0?'animate__animated animate__fadeInRightBig':'animate__animated animate__fadeInLeftBig'"></div>
          <div class="publicize1_2"></div>
          <div class="publicize1_3" :class="activeIndex==0?'animate__animated animate__fadeInLeftBig':'animate__animated animate__fadeInRightBig'">
            <div class="publicize1_3text1">宣传&教育</div>
            <div class="publicize1_3text2">Publicity & Education</div>
          </div>
        </div>
        <div class="swiper-slide"   id="publicize2 studyAdhd2">
          <div class="publicize2Back2"></div>
          <div :style="{backgroundImage:'url('+homeImge2+')'}" class="studyAdhd2_1">
            <div class="studyAdhdbox">
              <div class="studyAdhd2_1title">
                <div class="studyAdhd2_1Img" :class="activeIndex==1?'animate__animated animate__slideInDown':'animate__animated  animate__slideInUp'">
                </div>
              </div>
              <div class="studyAdhd2_1Text">
                <div v-html="homeItem2.typeInfo"></div>
              </div>
            </div>
          </div>
          <div class="studyAdhd2_2">
            <div class="studyAdhdbox studyAdhd2_2Box" v-if="homeItem2.remark">
              <div class="studyAdhd2_2left" :style="{backgroundImage:'url('+homeItem2.typePic+')'}" :class="activeIndex==1?'animate__animated animate__fadeInLeftBig':'animate__animated animate__fadeInRightBig'"></div>
              <div class="studyAdhd2_2right" :class="activeIndex==1?'animate__animated animate__fadeInRightBig':'animate__animated animate__fadeInLeftBig'">
                <div class="studyAdhd2_2right1">前沿研究</div>
                <div class="studyAdhd2_2right2">
                  <div v-html="homeItem2.remark[0].content"></div>
                </div>
                <div class="studyAdhd2_2right3"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="publicize3">
          <div class="publicize3_1"></div>
          <div class="publicize3back"></div>
          <div class="publicize3box allneibox allflex ">
            <div class="publicize3left" :class="activeIndex==2?'animate__animated animate__fadeInLeftBig':''">
              <div class="publicize3left1">学术活动</div>
              <div class="publicize3left2">
                <div v-html="homeItem3.remark"></div>
              </div>
              <div class="publicize3left3"></div>
            </div>
            <div class="publicize3right" :style="{backgroundImage:'url('+homeItem3.typePic+')'}" :class="activeIndex==2?'animate__animated animate__fadeInRightBig':''"></div>
          </div>
        </div>
        <div class="swiper-slide"  :style="{backgroundImage:'url('+homeImge4+')'}" id="publicize4">
          <div class="allneibox allflex publicize4box">
            <div class="publicize4title" :class="activeIndex==3?'animate__animated animate__fadeInDownBig':''">
              <div class="publicize4title1">
                客户故事
              </div>
              <div class="publicize4title2"></div>
            </div>
            <div class="publicize4item" v-if="homeItem4.remark">
              <div class="publicize4box1" :class="activeIndex==3?'animate__animated animate__flipInY':''">
                <div class="publicize4box1_11" :style="{backgroundImage:'url('+homeItem4.typePic[0]+')'}"></div>
                <div class="publicize4box1_2">{{ homeItem4.remark[0].name }}</div>
                <div class="publicize4box1_2">{{ homeItem4.remark[0].content }}</div>
              </div>
              <div class="publicize4box1" :class="activeIndex==3?'animate__animated animate__flipInY':''">
                <div class="publicize4box1_11" :style="{backgroundImage:'url('+homeItem4.typePic[1]+')'}"></div>
                <div class="publicize4box1_2">{{ homeItem4.remark[1].name }}</div>
                <div class="publicize4box1_2">{{ homeItem4.remark[1].content }}</div>
              </div>
              <div class="publicize4box1" :class="activeIndex==3?'animate__animated animate__flipInY':''">
                <div class="publicize4box1_11" :style="{backgroundImage:'url('+homeItem4.typePic[2]+')'}"></div>
                <div class="publicize4box1_2">{{ homeItem4.remark[2].name }}</div>
                <div class="publicize4box1_2">{{ homeItem4.remark[2].content }}</div>
              </div>

            </div>
          </div>
        </div>
        <div class="swiper-slide"  :style="{backgroundImage:'url('+homeImge5+')'}" id="publicize5">
          <div class="publicize5Title">
            ADHD月刊
          </div>
          <div class="publicize5fother"></div>
          <div class="turnClass" :class="activeIndex==4?'animate__animated animate__jackInTheBox':''">
            <div id="flipbook" v-if="homeItem5.typePic">
              <div v-for="(item,index) in homeItem5.typePic" :key="index">
                <div class="flipbookBox">
                  <img :src="item" style="width: 80%;height: 80%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="publicize6">
          <div class="publicize6back"></div>
          <div class="publicize6Title">
            培训与活动
          </div>
          <div class="publicize6box allflex allneibox" v-if="homeItem6.typePic">
            <div :class="activeIndex==5?'animate__animated animate__fadeInLeftBig':'animate__animated animate__fadeInRightBig'">
              <div class="publicize6box_11" :style="{backgroundImage:'url('+homeItem6.typePic[0]+')'}"></div>
              <div class="publicize6box_2">线下课程</div>
            </div>
            <div :class="activeIndex==5?'animate__animated animate__fadeInRightBig':'animate__animated animate__fadeInLeftBig'">
              <div class="publicize6box_12" :style="{backgroundImage:'url('+homeItem6.typePic[1]+')'}"></div>
              <div class="publicize6box_2">线上课程</div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="publicize7">
          <div class="publicize7">
            <div class="publicize7box">
              <div class="studyAdhdbox" style="display: flex;justify-content: space-between">
                <div class="publicize7boxleft" :class="activeIndex==6?'animate__animated animate__fadeInLeftBig':''">
                  <div class="publicize7boxleft1"></div>
                  <div class="publicize7boxleft2">
                    <div>中国（湖南）自由贸易试验区长沙片区长沙经开区区块人民东路二段166号海凭医疗器械产业园3#栋1206、1207室</div>
                    <div>13337816281</div>
                    <div>wuxia@bqbrain.com</div>
                  </div>
                </div>
                <div class="publicize7boxright" :class="activeIndex==6?'animate__animated animate__fadeInRightBig':''">
                  <div class="publicize7_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">了解ADHD</div>
                    <div>什么是注意力缺陷多动障碍</div>
                    <div>ADHD的数据和统计</div>
                    <div>诊断注意力缺陷多动障碍</div>
                    <div>注意力缺陷多动障碍的治疗</div>
                    <div>补充治疗</div>
                    <div>注意力缺陷多动障碍的共患病</div>
                    <div>常见问题</div>
                    <div>ADHD的误解</div>
                    <div>前沿</div>
                  </div>
                  <div class="publicize7_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">产品&解决方案</div>
                    <div>一键式海筛工具</div>
                    <div>医教家全面测评量表</div>
                    <div>精准用药</div>
                    <div>多维度神经心理测验</div>
                    <div>注意力测评及训练</div>
                    <div>家/校目标行为干预数字化工具</div>
                    <div>儿童专属正念</div>
                    <div>真实世界研究工具</div>
                    <div>家长培训课程</div>
                    <div>居家感与训练</div>
                    <div>IEP项目</div>
                  </div>
                  <div class="publicize7_boxbox" style="width: 230px;">
                    <div style="font-size: 36px;font-weight: bold;">宣传&教育</div>
                    <div>ADHDNews</div>
                    <div>ADHD月刊</div>
                    <div>培训与活动</div>
                    <div>客户故事</div>
                    <div>播客</div>
                    <div style="font-size: 36px;margin-top: 50px;font-weight: bold;">加入皕启</div>
                    <div>皕启脑科学</div>
                    <div>招聘岗位</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="studyAdhdfoother" :class="activeIndex==6?'animate__animated animate__backInUp':''">
              <div class="studyAdhdbox"
                   style="display: flex;justify-content: center;flex-wrap: wrap;color: #FFFFFF;align-items: center;height: 100%;">
                <div >
                  湖南皕启科技有限公司 © 2023 备案号:
                  <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #FFFFFF;text-decoration:none">湘ICP备2024066802号-1</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import turn from "@/util/turn"
import {websiteApiType} from "@/api/api";
export default {
  data() {
    return {
      swiperOption: {},
      activeIndex: 0,
      value: "",
      page: 1,
      contentData:"",
      imgUrl:[
        {imgurl:'image/1.jpg',index:1},
        {imgurl:'image/2.jpg',index:2},
        {imgurl:'image/3.jpg',index:3},
        {imgurl:'image/4.jpg',index:4},
        {imgurl:'image/5.jpg',index:5},
        {imgurl:'image/6.jpg',index:6},
        {imgurl:'image/7.jpg',index:7},
        {imgurl:'image/8.jpg',index:8},
        {imgurl:'image/9.jpg',index:9},
        {imgurl:'image/10.jpg',index:10},
        {imgurl:'image/11.jpg',index:11},
        {imgurl:'image/12.jpg',index:12},
      ],
      homeImge1:"",
      homeImge2:"",
      homeImge4:"",
      homeImge5:"",
      homeItem1:"",
      homeItem2:"",
      homeItem3:"",
      homeItem4:"",
      homeItem5:"",
      homeItem6:"",
    };
  },
  created() {
    this.$parent.changeTitleMenu(0)
  },
  computed: {},
  mounted() {
    this.getSwiper()
    this.onTurn();
    this.getAllData()
    this.getContData()
  },
  methods: {
    getContData(){
      let that = this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 9,
      }
      websiteApiType(data).then(res => {
        that.contentData = res.data.data.records
        for (let i = 0; i < this.contentData.length; i++) {
          if (this.contentData[i].typeName == '宣传&教育') {
            this.homeItem1 =this.contentData[i]
          }
          if (this.contentData[i].typeName == 'ADHDNEWS') {
            this.homeItem2 =this.contentData[i]
            this.homeItem2.remark = JSON.parse(this.contentData[i].remark)
            console.log(this.homeItem2.remark)
          }
          if (this.contentData[i].typeName == '学术活动') {
            this.homeItem3 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '展示') {
            this.homeItem4 =this.contentData[i]
            this.homeItem4.remark = JSON.parse(this.contentData[i].remark)
            this.homeItem4.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == 'ADHD月刊') {
            this.homeItem5 =this.contentData[i]
            this.homeItem5.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == '培训与活动') {
            this.homeItem6 =this.contentData[i]
            this.homeItem6.typePic = this.contentData[i].typePic.split(',')
          }
        }
      })
    },
    getAllData() {
      let that =this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 4,
      }
      websiteApiType(data).then(res => {
        let imgData = res.data.data.records
        for (let i = 0; i < imgData.length; i++) {
          let nameType = imgData[i].typeName
          if (nameType == "模块1") {
            that.homeImge1 = imgData[i].typePic;
          }
          if (nameType == "模块2") {
            that.homeImge2 = imgData[i].typePic;
          }
          if (nameType == "模块4") {
            that.homeImge4 = imgData[i].typePic;
          }
          if (nameType == "模块5") {
            that.homeImge5 = imgData[i].typePic;
          }
        }
      }).catch(err => console.log(err))
    },
    onTurn(){
      this.$nextTick(()=>{
        $("#flipbook").turn({
          autoCenter: true,
          height:646,
          width:996,
        });
      })

    },
    changewi(){
      this.swiperOption.slideTo(0);
    },
    getSwiper() {
      let that = this
      this.swiperOption = new Swiper(".swiper-container", {
        direction: 'vertical',
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          touchStart: function(e) {
            // 打印当前索引
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
          slideChange: function(e) {
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
        },
      })
    }
  }
};
</script>
<style scoped>
.turnClass{
  display: flex;
  margin: 0px auto;
  width: 996px;
  height: 646px;
  padding: calc((100vh - 646px)/2) 0;
  overflow: hidden;
}
</style>
<style scoped>
.publicize2Back2{
  background-image: url("@/static/publicize/publicize2_3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35vw;
  height: 35vw;
  position: absolute;
  right: -28vw;
  bottom: 5vh;
  animation: rotate 15s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}
.publicize6back{
  background-image: url("@/static/publicize/publicize6_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 20.67vw;
  height: 38.14vh;
  position: absolute;
  right:0;
  top: 0;
}
.publicize6Title{
  width: 100%;
  text-align: center;
  margin-top: 12.5vh;
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}
.publicize6box{

  margin-top: 20vh;
}
.publicize6box_11{
  width: 29.06vw;
  height: 38vh;
  background-image: url("@/static/publicize/publicize6_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.publicize6box_12{
  width: 29.06vw;
  height: 38vh;
  background-image: url("@/static/publicize/publicize6_3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.publicize6box_2{
  width: 100%;
  text-align: center;
  text-decoration:underline;
  font-size: 1.8vw;
  font-weight: 500;
  color: #0E1828;
  margin-top: 8.7vh;
}
.flipbookBox{
  width:100%;
  height: 100%;
  background-image: url("@/static/publicize/zazhi.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.publicize5Title{
  font-size: 1.8vw;
  font-family: Source-KeynoteartHans, Source-KeynoteartHans;
  font-weight: 400;
  color: #005DFF;
  position: absolute;
  left: 12.7vw;
  top: 7.87vh;
}
.publicize5fother{
  background-image: url("@/static/publicize/publicize5_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.64vw;
  height: 6.66vh;
  position: absolute;
  right:18px;
  bottom: 38px;
}
.publicize4box{
  margin-top: 11.58vh;
}
.publicize4title{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.publicize4item{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10vh;
}
.publicize4box1{
 background: rgba(0, 100, 182, 0.20);
  border-radius:12px;
  width: 21.97vw;
  height: 55.925vh;
}
.publicize4box1_2{
  width: 80%;
  margin: auto;
  margin-top: 20px;
  color: #ffffff;
}
.publicize4box1_11{
  background-image: url("@/static/publicize/publicize4_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 26.11vh;
}
.publicize4box1_12{
  background-image: url("@/static/publicize/publicize4_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 26.11vh;
}
.publicize4box1_13{
  background-image: url("@/static/publicize/publicize4_3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 26.11vh;
}

.publicize4title1{
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  height: 7vh;
}
.publicize4title2{
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}
.publicize3left{
  width:33.59vw;
}
.publicize3left1{
  font-size: 1.82vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}
.publicize3back{
  width: 23.95vw;
  height: 54.16vh;
  background: linear-gradient(135deg, #005DFF 0%, #55FDF4 100%);
  border-radius: 1.25vw 0px 0px 1.25vw;
  position: absolute;
  right: 0;
  top: 20%;
}
.publicize3left2{
  margin-top: 4vh;
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  line-height: 2;
}
.publicize3left3{
  margin-top: 5vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}
.publicize3right{
  background-image: url("@/static/publicize/publicize3right.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width:30.625vw;
  height: 39.9vh;
}
.publicize3box{
  margin-top: 30vh;
}
.publicize3_1{
  background-image: url("@/static/publicize/publicize3_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width:9.375vw;
  height: 9.375vw;
  position: absolute;
  left: 0;
  bottom: 11.2vh;
}
#publicize1,#publicize2,#publicize3,#publicize4,#publicize5,#publicize6,#publicize7{
  position: relative;
  width: 100%;
  height: 100%;
}
.publicize{
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  overflow: hidden;
}
.studyAdhd2_1{
  background-image: url("@/static/studyAdhd/studyAdhd2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width:100vw;
  height: 27.87vh;
  position: relative;
}
.studyAdhd2_1title{
  width: 100%;
  height: 13.88vh;
}
.studyAdhd2_1Img{
  background-image: url("@/static/publicize/publicize2_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 12.91vw;
  height: 11.38vh;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  padding-top: 7vh;
  box-sizing: border-box;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
}
.studyAdhd2_1Text{
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #15233B;
  line-height: 2;
}
.studyAdhd2_2Box{
  display: flex;
  justify-content: space-between;
  margin-top: 10vh !important;
}
.studyAdhd2_2left{
  background-image: url("@/static/publicize/publicize2_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 32.6vw;
  height: 49.81vh;
}
.studyAdhd2_2right{
  width: 30vw;
  height: 49.81vh;
}
.studyAdhd2_2right1{
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
  margin-top: 8vh;
}
.studyAdhd2_2right2{
  font-size: 0.7vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  margin-top: 2.5vw;
  line-height: 3;
}
.studyAdhd2_2right3{
  margin-top: 3vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}
.studyAdhd2_2right3Img{
  margin-top: 3vh;
  background-image: url("@/static/studyAdhd/studyAdhd2_2right3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 8.36vw;
  height: 4.1vh;
}
.publicize7box{
  background-image:  url("@/static/studyAdhd/studyAdhd11.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 70vh;
}
.studyAdhdfoother{
  width: 100%;
  height: 10vh;
  background: #363636;
}
.publicize7boxleft1{
  background-image: url("@/static/studyAdhd/studyAdhd11boxleft1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.69vw;
  height:9.7vh;
}
.studyAdhdbox{
  width: 75vw;
  margin: auto;
  position: relative;
}
.publicize7boxleft2{
  font-size: 0.6vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  margin-top: 3vh;
}
.publicize7{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.publicize7boxright{
  display: flex;
  justify-content: space-between;
  width: 44vw;
  line-height: 3;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  right: 0 !important;
  left: 3% !important;
  width: 50px !important;
}

.swiper-pagination>>>.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  border: 0;
  margin: 10px 0 0 10px;
  border-radius: 50%;
  background: #005dff;
}

.swiper-pagination>>>.swiper-pagination-bullet-active {
  margin: 10px 0 0 7px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: linear-gradient(180deg, #55FDF4 0%, #005DFF 100%);
}
#publicize1{
  background-image: url("@/static/publicize/publicize1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
#publicize4{
  background-image: url("@/static/publicize/publicize4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
#publicize5{
  background-image: url("@/static/publicize/publicize5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.publicize1_1{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 32.23vw;
  height: 57.87vh;
  position: absolute;
  right: 9vw;
  bottom: 15.37vh;
}
.publicize1_2{
  background-image: url("@/static/publicize/publicize1_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 49.73vw;
  height: 26.45vh;
  position: absolute;
  left: 0vw;
  bottom: 0vh;
}
.publicize1_3{
  position: absolute;
  top: 41.3vh;
  left: 12.86vw;
  text-align: center;
}
.publicize1_3text1{
  font-size: 5.7vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #15233B;
}
.publicize1_3text2{
  margin-top: 0.87vh;
  font-size: 2.7vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #0FA9FF;
}
</style>
